/* ////// loading */


.wrap-loading{
    width: 100%;
    height: 100vh;
    position:absolute;
    z-index: 1000000;
    background-color:rgba(0,0,0,0.3);
    text-align: center;
}



.kart-loader *, .kart-loader *:after, .kart-loader *:before {
	box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
}


.kart-loader {
	position: relative;
	margin: 97px auto;
	display:block;
	width:49px;
}

.sheath {
	position: absolute;
	transform-origin: 50% 50%;
		-o-transform-origin: 50% 50%;
		-ms-transform-origin: 50% 50%;
		-webkit-transform-origin: 50% 50%;
		-moz-transform-origin: 50% 50%;
}

.segment {
	background-color: rgba(255,110,110,0.97);
	border-radius: 27px;
	height: 6px;
	transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-webkit-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
	width: 18px;
}



.sheath:nth-child(1) {
	animation: segment-orbit-1 2070ms infinite linear, segment-opacity-1 1293.75ms infinite linear;
		-o-animation: segment-orbit-1 2070ms infinite linear, segment-opacity-1 1293.75ms infinite linear;
		-ms-animation: segment-orbit-1 2070ms infinite linear, segment-opacity-1 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-1 2070ms infinite linear, segment-opacity-1 1293.75ms infinite linear;
		-moz-animation: segment-orbit-1 2070ms infinite linear, segment-opacity-1 1293.75ms infinite linear;
	transform: rotate(-30deg) translate(23px);
		-o-transform: rotate(-30deg) translate(23px);
		-ms-transform: rotate(-30deg) translate(23px);
		-webkit-transform: rotate(-30deg) translate(23px);
		-moz-transform: rotate(-30deg) translate(23px);
}
.sheath:nth-child(1) .segment {
	animation: segment-scale-1 1293.75ms infinite linear;
		-o-animation: segment-scale-1 1293.75ms infinite linear;
		-ms-animation: segment-scale-1 1293.75ms infinite linear;
		-webkit-animation: segment-scale-1 1293.75ms infinite linear;
		-moz-animation: segment-scale-1 1293.75ms infinite linear;
}



.sheath:nth-child(2) {
	animation: segment-orbit-2 2070ms infinite linear, segment-opacity-2 1293.75ms infinite linear;
		-o-animation: segment-orbit-2 2070ms infinite linear, segment-opacity-2 1293.75ms infinite linear;
		-ms-animation: segment-orbit-2 2070ms infinite linear, segment-opacity-2 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-2 2070ms infinite linear, segment-opacity-2 1293.75ms infinite linear;
		-moz-animation: segment-orbit-2 2070ms infinite linear, segment-opacity-2 1293.75ms infinite linear;
	transform: rotate(-60deg) translate(23px);
		-o-transform: rotate(-60deg) translate(23px);
		-ms-transform: rotate(-60deg) translate(23px);
		-webkit-transform: rotate(-60deg) translate(23px);
		-moz-transform: rotate(-60deg) translate(23px);
}
.sheath:nth-child(2) .segment {
	animation: segment-scale-2 1293.75ms infinite linear;
		-o-animation: segment-scale-2 1293.75ms infinite linear;
		-ms-animation: segment-scale-2 1293.75ms infinite linear;
		-webkit-animation: segment-scale-2 1293.75ms infinite linear;
		-moz-animation: segment-scale-2 1293.75ms infinite linear;
}



.sheath:nth-child(3) {
	animation: segment-orbit-3 2070ms infinite linear, segment-opacity-3 1293.75ms infinite linear;
		-o-animation: segment-orbit-3 2070ms infinite linear, segment-opacity-3 1293.75ms infinite linear;
		-ms-animation: segment-orbit-3 2070ms infinite linear, segment-opacity-3 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-3 2070ms infinite linear, segment-opacity-3 1293.75ms infinite linear;
		-moz-animation: segment-orbit-3 2070ms infinite linear, segment-opacity-3 1293.75ms infinite linear;
	transform: rotate(-90deg) translate(23px);
		-o-transform: rotate(-90deg) translate(23px);
		-ms-transform: rotate(-90deg) translate(23px);
		-webkit-transform: rotate(-90deg) translate(23px);
		-moz-transform: rotate(-90deg) translate(23px);
}
.sheath:nth-child(3) .segment {
	animation: segment-scale-3 1293.75ms infinite linear;
		-o-animation: segment-scale-3 1293.75ms infinite linear;
		-ms-animation: segment-scale-3 1293.75ms infinite linear;
		-webkit-animation: segment-scale-3 1293.75ms infinite linear;
		-moz-animation: segment-scale-3 1293.75ms infinite linear;
}



.sheath:nth-child(4) {
	animation: segment-orbit-4 2070ms infinite linear, segment-opacity-4 1293.75ms infinite linear;
		-o-animation: segment-orbit-4 2070ms infinite linear, segment-opacity-4 1293.75ms infinite linear;
		-ms-animation: segment-orbit-4 2070ms infinite linear, segment-opacity-4 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-4 2070ms infinite linear, segment-opacity-4 1293.75ms infinite linear;
		-moz-animation: segment-orbit-4 2070ms infinite linear, segment-opacity-4 1293.75ms infinite linear;
	transform: rotate(-120deg) translate(23px);
		-o-transform: rotate(-120deg) translate(23px);
		-ms-transform: rotate(-120deg) translate(23px);
		-webkit-transform: rotate(-120deg) translate(23px);
		-moz-transform: rotate(-120deg) translate(23px);
}
.sheath:nth-child(4) .segment {
	animation: segment-scale-4 1293.75ms infinite linear;
		-o-animation: segment-scale-4 1293.75ms infinite linear;
		-ms-animation: segment-scale-4 1293.75ms infinite linear;
		-webkit-animation: segment-scale-4 1293.75ms infinite linear;
		-moz-animation: segment-scale-4 1293.75ms infinite linear;
}



.sheath:nth-child(5) {
	animation: segment-orbit-5 2070ms infinite linear, segment-opacity-5 1293.75ms infinite linear;
		-o-animation: segment-orbit-5 2070ms infinite linear, segment-opacity-5 1293.75ms infinite linear;
		-ms-animation: segment-orbit-5 2070ms infinite linear, segment-opacity-5 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-5 2070ms infinite linear, segment-opacity-5 1293.75ms infinite linear;
		-moz-animation: segment-orbit-5 2070ms infinite linear, segment-opacity-5 1293.75ms infinite linear;
	transform: rotate(-150deg) translate(23px);
		-o-transform: rotate(-150deg) translate(23px);
		-ms-transform: rotate(-150deg) translate(23px);
		-webkit-transform: rotate(-150deg) translate(23px);
		-moz-transform: rotate(-150deg) translate(23px);
}
.sheath:nth-child(5) .segment {
	animation: segment-scale-5 1293.75ms infinite linear;
		-o-animation: segment-scale-5 1293.75ms infinite linear;
		-ms-animation: segment-scale-5 1293.75ms infinite linear;
		-webkit-animation: segment-scale-5 1293.75ms infinite linear;
		-moz-animation: segment-scale-5 1293.75ms infinite linear;
}



.sheath:nth-child(6) {
	animation: segment-orbit-6 2070ms infinite linear, segment-opacity-6 1293.75ms infinite linear;
		-o-animation: segment-orbit-6 2070ms infinite linear, segment-opacity-6 1293.75ms infinite linear;
		-ms-animation: segment-orbit-6 2070ms infinite linear, segment-opacity-6 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-6 2070ms infinite linear, segment-opacity-6 1293.75ms infinite linear;
		-moz-animation: segment-orbit-6 2070ms infinite linear, segment-opacity-6 1293.75ms infinite linear;
	transform: rotate(-180deg) translate(23px);
		-o-transform: rotate(-180deg) translate(23px);
		-ms-transform: rotate(-180deg) translate(23px);
		-webkit-transform: rotate(-180deg) translate(23px);
		-moz-transform: rotate(-180deg) translate(23px);
}
.sheath:nth-child(6) .segment {
	animation: segment-scale-6 1293.75ms infinite linear;
		-o-animation: segment-scale-6 1293.75ms infinite linear;
		-ms-animation: segment-scale-6 1293.75ms infinite linear;
		-webkit-animation: segment-scale-6 1293.75ms infinite linear;
		-moz-animation: segment-scale-6 1293.75ms infinite linear;
}



.sheath:nth-child(7) {
	animation: segment-orbit-7 2070ms infinite linear, segment-opacity-7 1293.75ms infinite linear;
		-o-animation: segment-orbit-7 2070ms infinite linear, segment-opacity-7 1293.75ms infinite linear;
		-ms-animation: segment-orbit-7 2070ms infinite linear, segment-opacity-7 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-7 2070ms infinite linear, segment-opacity-7 1293.75ms infinite linear;
		-moz-animation: segment-orbit-7 2070ms infinite linear, segment-opacity-7 1293.75ms infinite linear;
	transform: rotate(-210deg) translate(23px);
		-o-transform: rotate(-210deg) translate(23px);
		-ms-transform: rotate(-210deg) translate(23px);
		-webkit-transform: rotate(-210deg) translate(23px);
		-moz-transform: rotate(-210deg) translate(23px);
}
.sheath:nth-child(7) .segment {
	animation: segment-scale-7 1293.75ms infinite linear;
		-o-animation: segment-scale-7 1293.75ms infinite linear;
		-ms-animation: segment-scale-7 1293.75ms infinite linear;
		-webkit-animation: segment-scale-7 1293.75ms infinite linear;
		-moz-animation: segment-scale-7 1293.75ms infinite linear;
}



.sheath:nth-child(8) {
	animation: segment-orbit-8 2070ms infinite linear, segment-opacity-8 1293.75ms infinite linear;
		-o-animation: segment-orbit-8 2070ms infinite linear, segment-opacity-8 1293.75ms infinite linear;
		-ms-animation: segment-orbit-8 2070ms infinite linear, segment-opacity-8 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-8 2070ms infinite linear, segment-opacity-8 1293.75ms infinite linear;
		-moz-animation: segment-orbit-8 2070ms infinite linear, segment-opacity-8 1293.75ms infinite linear;
	transform: rotate(-240deg) translate(23px);
		-o-transform: rotate(-240deg) translate(23px);
		-ms-transform: rotate(-240deg) translate(23px);
		-webkit-transform: rotate(-240deg) translate(23px);
		-moz-transform: rotate(-240deg) translate(23px);
}
.sheath:nth-child(8) .segment {
	animation: segment-scale-8 1293.75ms infinite linear;
		-o-animation: segment-scale-8 1293.75ms infinite linear;
		-ms-animation: segment-scale-8 1293.75ms infinite linear;
		-webkit-animation: segment-scale-8 1293.75ms infinite linear;
		-moz-animation: segment-scale-8 1293.75ms infinite linear;
}



.sheath:nth-child(9) {
	animation: segment-orbit-9 2070ms infinite linear, segment-opacity-9 1293.75ms infinite linear;
		-o-animation: segment-orbit-9 2070ms infinite linear, segment-opacity-9 1293.75ms infinite linear;
		-ms-animation: segment-orbit-9 2070ms infinite linear, segment-opacity-9 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-9 2070ms infinite linear, segment-opacity-9 1293.75ms infinite linear;
		-moz-animation: segment-orbit-9 2070ms infinite linear, segment-opacity-9 1293.75ms infinite linear;
	transform: rotate(-270deg) translate(23px);
		-o-transform: rotate(-270deg) translate(23px);
		-ms-transform: rotate(-270deg) translate(23px);
		-webkit-transform: rotate(-270deg) translate(23px);
		-moz-transform: rotate(-270deg) translate(23px);
}
.sheath:nth-child(9) .segment {
	animation: segment-scale-9 1293.75ms infinite linear;
		-o-animation: segment-scale-9 1293.75ms infinite linear;
		-ms-animation: segment-scale-9 1293.75ms infinite linear;
		-webkit-animation: segment-scale-9 1293.75ms infinite linear;
		-moz-animation: segment-scale-9 1293.75ms infinite linear;
}



.sheath:nth-child(10) {
	animation: segment-orbit-10 2070ms infinite linear, segment-opacity-10 1293.75ms infinite linear;
		-o-animation: segment-orbit-10 2070ms infinite linear, segment-opacity-10 1293.75ms infinite linear;
		-ms-animation: segment-orbit-10 2070ms infinite linear, segment-opacity-10 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-10 2070ms infinite linear, segment-opacity-10 1293.75ms infinite linear;
		-moz-animation: segment-orbit-10 2070ms infinite linear, segment-opacity-10 1293.75ms infinite linear;
	transform: rotate(-300deg) translate(23px);
		-o-transform: rotate(-300deg) translate(23px);
		-ms-transform: rotate(-300deg) translate(23px);
		-webkit-transform: rotate(-300deg) translate(23px);
		-moz-transform: rotate(-300deg) translate(23px);
}
.sheath:nth-child(10) .segment {
	animation: segment-scale-10 1293.75ms infinite linear;
		-o-animation: segment-scale-10 1293.75ms infinite linear;
		-ms-animation: segment-scale-10 1293.75ms infinite linear;
		-webkit-animation: segment-scale-10 1293.75ms infinite linear;
		-moz-animation: segment-scale-10 1293.75ms infinite linear;
}



.sheath:nth-child(11) {
	animation: segment-orbit-11 2070ms infinite linear, segment-opacity-11 1293.75ms infinite linear;
		-o-animation: segment-orbit-11 2070ms infinite linear, segment-opacity-11 1293.75ms infinite linear;
		-ms-animation: segment-orbit-11 2070ms infinite linear, segment-opacity-11 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-11 2070ms infinite linear, segment-opacity-11 1293.75ms infinite linear;
		-moz-animation: segment-orbit-11 2070ms infinite linear, segment-opacity-11 1293.75ms infinite linear;
	transform: rotate(-330deg) translate(23px);
		-o-transform: rotate(-330deg) translate(23px);
		-ms-transform: rotate(-330deg) translate(23px);
		-webkit-transform: rotate(-330deg) translate(23px);
		-moz-transform: rotate(-330deg) translate(23px);
}

.sheath:nth-child(11) .segment {
	animation: segment-scale-11 1293.75ms infinite linear;
		-o-animation: segment-scale-11 1293.75ms infinite linear;
		-ms-animation: segment-scale-11 1293.75ms infinite linear;
		-webkit-animation: segment-scale-11 1293.75ms infinite linear;
		-moz-animation: segment-scale-11 1293.75ms infinite linear;
}



.sheath:nth-child(12) {
	animation: segment-orbit-12 2070ms infinite linear, segment-opacity-12 1293.75ms infinite linear;
		-o-animation: segment-orbit-12 2070ms infinite linear, segment-opacity-12 1293.75ms infinite linear;
		-ms-animation: segment-orbit-12 2070ms infinite linear, segment-opacity-12 1293.75ms infinite linear;
		-webkit-animation: segment-orbit-12 2070ms infinite linear, segment-opacity-12 1293.75ms infinite linear;
		-moz-animation: segment-orbit-12 2070ms infinite linear, segment-opacity-12 1293.75ms infinite linear;
	transform: rotate(-360deg) translate(23px);
		-o-transform: rotate(-360deg) translate(23px);
		-ms-transform: rotate(-360deg) translate(23px);
		-webkit-transform: rotate(-360deg) translate(23px);
		-moz-transform: rotate(-360deg) translate(23px);
}

.sheath:nth-child(12) .segment {
	animation: segment-scale-12 1293.75ms infinite linear;
		-o-animation: segment-scale-12 1293.75ms infinite linear;
		-ms-animation: segment-scale-12 1293.75ms infinite linear;
		-webkit-animation: segment-scale-12 1293.75ms infinite linear;
		-moz-animation: segment-scale-12 1293.75ms infinite linear;
}







@keyframes segment-orbit-1 {
	from {
		transform: rotate(30deg) translate(23px);
	}
	50% {
		transform: rotate(210deg) translate(28px);
	}
	to {
		transform: rotate(390deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-1 {
	from {
		-o-transform: rotate(30deg) translate(23px);
	}
	50% {
		-o-transform: rotate(210deg) translate(28px);
	}
	to {
		-o-transform: rotate(390deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-1 {
	from {
		-ms-transform: rotate(30deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(210deg) translate(28px);
	}
	to {
		-ms-transform: rotate(390deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-1 {
	from {
		-webkit-transform: rotate(30deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(210deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(390deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-1 {
	from {
		-moz-transform: rotate(30deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(210deg) translate(28px);
	}
	to {
		-moz-transform: rotate(390deg) translate(23px);
	}
}

@keyframes segment-orbit-2 {
	from {
		transform: rotate(60deg) translate(23px);
	}
	50% {
		transform: rotate(240deg) translate(28px);
	}
	to {
		transform: rotate(420deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-2 {
	from {
		-o-transform: rotate(60deg) translate(23px);
	}
	50% {
		-o-transform: rotate(240deg) translate(28px);
	}
	to {
		-o-transform: rotate(420deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-2 {
	from {
		-ms-transform: rotate(60deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(240deg) translate(28px);
	}
	to {
		-ms-transform: rotate(420deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-2 {
	from {
		-webkit-transform: rotate(60deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(240deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(420deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-2 {
	from {
		-moz-transform: rotate(60deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(240deg) translate(28px);
	}
	to {
		-moz-transform: rotate(420deg) translate(23px);
	}
}

@keyframes segment-orbit-3 {
	from {
		transform: rotate(90deg) translate(23px);
	}
	50% {
		transform: rotate(270deg) translate(28px);
	}
	to {
		transform: rotate(450deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-3 {
	from {
		-o-transform: rotate(90deg) translate(23px);
	}
	50% {
		-o-transform: rotate(270deg) translate(28px);
	}
	to {
		-o-transform: rotate(450deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-3 {
	from {
		-ms-transform: rotate(90deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(270deg) translate(28px);
	}
	to {
		-ms-transform: rotate(450deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-3 {
	from {
		-webkit-transform: rotate(90deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(270deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(450deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-3 {
	from {
		-moz-transform: rotate(90deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(270deg) translate(28px);
	}
	to {
		-moz-transform: rotate(450deg) translate(23px);
	}
}

@keyframes segment-orbit-4 {
	from {
		transform: rotate(120deg) translate(23px);
	}
	50% {
		transform: rotate(300deg) translate(28px);
	}
	to {
		transform: rotate(480deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-4 {
	from {
		-o-transform: rotate(120deg) translate(23px);
	}
	50% {
		-o-transform: rotate(300deg) translate(28px);
	}
	to {
		-o-transform: rotate(480deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-4 {
	from {
		-ms-transform: rotate(120deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(300deg) translate(28px);
	}
	to {
		-ms-transform: rotate(480deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-4 {
	from {
		-webkit-transform: rotate(120deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(300deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(480deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-4 {
	from {
		-moz-transform: rotate(120deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(300deg) translate(28px);
	}
	to {
		-moz-transform: rotate(480deg) translate(23px);
	}
}

@keyframes segment-orbit-5 {
	from {
		transform: rotate(150deg) translate(23px);
	}
	50% {
		transform: rotate(330deg) translate(28px);
	}
	to {
		transform: rotate(510deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-5 {
	from {
		-o-transform: rotate(150deg) translate(23px);
	}
	50% {
		-o-transform: rotate(330deg) translate(28px);
	}
	to {
		-o-transform: rotate(510deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-5 {
	from {
		-ms-transform: rotate(150deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(330deg) translate(28px);
	}
	to {
		-ms-transform: rotate(510deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-5 {
	from {
		-webkit-transform: rotate(150deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(330deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(510deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-5 {
	from {
		-moz-transform: rotate(150deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(330deg) translate(28px);
	}
	to {
		-moz-transform: rotate(510deg) translate(23px);
	}
}

@keyframes segment-orbit-6 {
	from {
		transform: rotate(180deg) translate(23px);
	}
	50% {
		transform: rotate(360deg) translate(28px);
	}
	to {
		transform: rotate(540deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-6 {
	from {
		-o-transform: rotate(180deg) translate(23px);
	}
	50% {
		-o-transform: rotate(360deg) translate(28px);
	}
	to {
		-o-transform: rotate(540deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-6 {
	from {
		-ms-transform: rotate(180deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(360deg) translate(28px);
	}
	to {
		-ms-transform: rotate(540deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-6 {
	from {
		-webkit-transform: rotate(180deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(360deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(540deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-6 {
	from {
		-moz-transform: rotate(180deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(360deg) translate(28px);
	}
	to {
		-moz-transform: rotate(540deg) translate(23px);
	}
}

@keyframes segment-orbit-7 {
	from {
		transform: rotate(210deg) translate(23px);
	}
	50% {
		transform: rotate(390deg) translate(28px);
	}
	to {
		transform: rotate(570deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-7 {
	from {
		-o-transform: rotate(210deg) translate(23px);
	}
	50% {
		-o-transform: rotate(390deg) translate(28px);
	}
	to {
		-o-transform: rotate(570deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-7 {
	from {
		-ms-transform: rotate(210deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(390deg) translate(28px);
	}
	to {
		-ms-transform: rotate(570deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-7 {
	from {
		-webkit-transform: rotate(210deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(390deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(570deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-7 {
	from {
		-moz-transform: rotate(210deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(390deg) translate(28px);
	}
	to {
		-moz-transform: rotate(570deg) translate(23px);
	}
}

@keyframes segment-orbit-8 {
	from {
		transform: rotate(240deg) translate(23px);
	}
	50% {
		transform: rotate(420deg) translate(28px);
	}
	to {
		transform: rotate(600deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-8 {
	from {
		-o-transform: rotate(240deg) translate(23px);
	}
	50% {
		-o-transform: rotate(420deg) translate(28px);
	}
	to {
		-o-transform: rotate(600deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-8 {
	from {
		-ms-transform: rotate(240deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(420deg) translate(28px);
	}
	to {
		-ms-transform: rotate(600deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-8 {
	from {
		-webkit-transform: rotate(240deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(420deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(600deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-8 {
	from {
		-moz-transform: rotate(240deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(420deg) translate(28px);
	}
	to {
		-moz-transform: rotate(600deg) translate(23px);
	}
}

@keyframes segment-orbit-9 {
	from {
		transform: rotate(270deg) translate(23px);
	}
	50% {
		transform: rotate(450deg) translate(28px);
	}
	to {
		transform: rotate(630deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-9 {
	from {
		-o-transform: rotate(270deg) translate(23px);
	}
	50% {
		-o-transform: rotate(450deg) translate(28px);
	}
	to {
		-o-transform: rotate(630deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-9 {
	from {
		-ms-transform: rotate(270deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(450deg) translate(28px);
	}
	to {
		-ms-transform: rotate(630deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-9 {
	from {
		-webkit-transform: rotate(270deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(450deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(630deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-9 {
	from {
		-moz-transform: rotate(270deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(450deg) translate(28px);
	}
	to {
		-moz-transform: rotate(630deg) translate(23px);
	}
}

@keyframes segment-orbit-10 {
	from {
		transform: rotate(300deg) translate(23px);
	}
	50% {
		transform: rotate(480deg) translate(28px);
	}
	to {
		transform: rotate(660deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-10 {
	from {
		-o-transform: rotate(300deg) translate(23px);
	}
	50% {
		-o-transform: rotate(480deg) translate(28px);
	}
	to {
		-o-transform: rotate(660deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-10 {
	from {
		-ms-transform: rotate(300deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(480deg) translate(28px);
	}
	to {
		-ms-transform: rotate(660deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-10 {
	from {
		-webkit-transform: rotate(300deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(480deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(660deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-10 {
	from {
		-moz-transform: rotate(300deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(480deg) translate(28px);
	}
	to {
		-moz-transform: rotate(660deg) translate(23px);
	}
}

@keyframes segment-orbit-11 {
	from {
		transform: rotate(330deg) translate(23px);
	}
	50% {
		transform: rotate(510deg) translate(28px);
	}
	to {
		transform: rotate(690deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-11 {
	from {
		-o-transform: rotate(330deg) translate(23px);
	}
	50% {
		-o-transform: rotate(510deg) translate(28px);
	}
	to {
		-o-transform: rotate(690deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-11 {
	from {
		-ms-transform: rotate(330deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(510deg) translate(28px);
	}
	to {
		-ms-transform: rotate(690deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-11 {
	from {
		-webkit-transform: rotate(330deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(510deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(690deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-11 {
	from {
		-moz-transform: rotate(330deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(510deg) translate(28px);
	}
	to {
		-moz-transform: rotate(690deg) translate(23px);
	}
}

@keyframes segment-orbit-12 {
	from {
		transform: rotate(360deg) translate(23px);
	}
	50% {
		transform: rotate(540deg) translate(28px);
	}
	to {
		transform: rotate(720deg) translate(23px);
	}
}

@-o-keyframes segment-orbit-12 {
	from {
		-o-transform: rotate(360deg) translate(23px);
	}
	50% {
		-o-transform: rotate(540deg) translate(28px);
	}
	to {
		-o-transform: rotate(720deg) translate(23px);
	}
}

@-ms-keyframes segment-orbit-12 {
	from {
		-ms-transform: rotate(360deg) translate(23px);
	}
	50% {
		-ms-transform: rotate(540deg) translate(28px);
	}
	to {
		-ms-transform: rotate(720deg) translate(23px);
	}
}

@-webkit-keyframes segment-orbit-12 {
	from {
		-webkit-transform: rotate(360deg) translate(23px);
	}
	50% {
		-webkit-transform: rotate(540deg) translate(28px);
	}
	to {
		-webkit-transform: rotate(720deg) translate(23px);
	}
}

@-moz-keyframes segment-orbit-12 {
	from {
		-moz-transform: rotate(360deg) translate(23px);
	}
	50% {
		-moz-transform: rotate(540deg) translate(28px);
	}
	to {
		-moz-transform: rotate(720deg) translate(23px);
	}
}

@keyframes segment-scale-12 {
	0% {
		transform: scaleX(1);
	}
	8.33333% {
		transform: scaleX(0.93333);
	}
	16.66667% {
		transform: scaleX(0.86667);
	}
	25% {
		transform: scaleX(0.8);
	}
	33.33333% {
		transform: scaleX(0.73333);
	}
	41.66667% {
		transform: scaleX(0.66667);
	}
	50% {
		transform: scaleX(0.6);
	}
	58.33333% {
		transform: scaleX(0.53333);
	}
	66.66667% {
		transform: scaleX(0.46667);
	}
	75% {
		transform: scaleX(0.4);
	}
	83.33333% {
		transform: scaleX(0.33333);
	}
	91.66667% {
		transform: scaleX(0.26667);
	}
	100% {
		transform: scaleX(1);
	}
}

@-o-keyframes segment-scale-12 {
	0% {
		-o-transform: scaleX(1);
	}
	8.33333% {
		-o-transform: scaleX(0.93333);
	}
	16.66667% {
		-o-transform: scaleX(0.86667);
	}
	25% {
		-o-transform: scaleX(0.8);
	}
	33.33333% {
		-o-transform: scaleX(0.73333);
	}
	41.66667% {
		-o-transform: scaleX(0.66667);
	}
	50% {
		-o-transform: scaleX(0.6);
	}
	58.33333% {
		-o-transform: scaleX(0.53333);
	}
	66.66667% {
		-o-transform: scaleX(0.46667);
	}
	75% {
		-o-transform: scaleX(0.4);
	}
	83.33333% {
		-o-transform: scaleX(0.33333);
	}
	91.66667% {
		-o-transform: scaleX(0.26667);
	}
	100% {
		-o-transform: scaleX(1);
	}
}

@-ms-keyframes segment-scale-12 {
	0% {
		-ms-transform: scaleX(1);
	}
	8.33333% {
		-ms-transform: scaleX(0.93333);
	}
	16.66667% {
		-ms-transform: scaleX(0.86667);
	}
	25% {
		-ms-transform: scaleX(0.8);
	}
	33.33333% {
		-ms-transform: scaleX(0.73333);
	}
	41.66667% {
		-ms-transform: scaleX(0.66667);
	}
	50% {
		-ms-transform: scaleX(0.6);
	}
	58.33333% {
		-ms-transform: scaleX(0.53333);
	}
	66.66667% {
		-ms-transform: scaleX(0.46667);
	}
	75% {
		-ms-transform: scaleX(0.4);
	}
	83.33333% {
		-ms-transform: scaleX(0.33333);
	}
	91.66667% {
		-ms-transform: scaleX(0.26667);
	}
	100% {
		-ms-transform: scaleX(1);
	}
}

@-webkit-keyframes segment-scale-12 {
	0% {
		-webkit-transform: scaleX(1);
	}
	8.33333% {
		-webkit-transform: scaleX(0.93333);
	}
	16.66667% {
		-webkit-transform: scaleX(0.86667);
	}
	25% {
		-webkit-transform: scaleX(0.8);
	}
	33.33333% {
		-webkit-transform: scaleX(0.73333);
	}
	41.66667% {
		-webkit-transform: scaleX(0.66667);
	}
	50% {
		-webkit-transform: scaleX(0.6);
	}
	58.33333% {
		-webkit-transform: scaleX(0.53333);
	}
	66.66667% {
		-webkit-transform: scaleX(0.46667);
	}
	75% {
		-webkit-transform: scaleX(0.4);
	}
	83.33333% {
		-webkit-transform: scaleX(0.33333);
	}
	91.66667% {
		-webkit-transform: scaleX(0.26667);
	}
	100% {
		-webkit-transform: scaleX(1);
	}
}

@-moz-keyframes segment-scale-12 {
	0% {
		-moz-transform: scaleX(1);
	}
	8.33333% {
		-moz-transform: scaleX(0.93333);
	}
	16.66667% {
		-moz-transform: scaleX(0.86667);
	}
	25% {
		-moz-transform: scaleX(0.8);
	}
	33.33333% {
		-moz-transform: scaleX(0.73333);
	}
	41.66667% {
		-moz-transform: scaleX(0.66667);
	}
	50% {
		-moz-transform: scaleX(0.6);
	}
	58.33333% {
		-moz-transform: scaleX(0.53333);
	}
	66.66667% {
		-moz-transform: scaleX(0.46667);
	}
	75% {
		-moz-transform: scaleX(0.4);
	}
	83.33333% {
		-moz-transform: scaleX(0.33333);
	}
	91.66667% {
		-moz-transform: scaleX(0.26667);
	}
	100% {
		-moz-transform: scaleX(1);
	}
}

@keyframes segment-scale-11 {
	0% {
		transform: scaleX(0.93333);
	}
	8.33333% {
		transform: scaleX(0.86667);
	}
	16.66667% {
		transform: scaleX(0.8);
	}
	25% {
		transform: scaleX(0.73333);
	}
	33.33333% {
		transform: scaleX(0.66667);
	}
	41.66667% {
		transform: scaleX(0.6);
	}
	50% {
		transform: scaleX(0.53333);
	}
	58.33333% {
		transform: scaleX(0.46667);
	}
	66.66667% {
		transform: scaleX(0.4);
	}
	75% {
		transform: scaleX(0.33333);
	}
	83.33333% {
		transform: scaleX(0.26667);
	}
	91.66667% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0.93333);
	}
}

@-o-keyframes segment-scale-11 {
	0% {
		-o-transform: scaleX(0.93333);
	}
	8.33333% {
		-o-transform: scaleX(0.86667);
	}
	16.66667% {
		-o-transform: scaleX(0.8);
	}
	25% {
		-o-transform: scaleX(0.73333);
	}
	33.33333% {
		-o-transform: scaleX(0.66667);
	}
	41.66667% {
		-o-transform: scaleX(0.6);
	}
	50% {
		-o-transform: scaleX(0.53333);
	}
	58.33333% {
		-o-transform: scaleX(0.46667);
	}
	66.66667% {
		-o-transform: scaleX(0.4);
	}
	75% {
		-o-transform: scaleX(0.33333);
	}
	83.33333% {
		-o-transform: scaleX(0.26667);
	}
	91.66667% {
		-o-transform: scaleX(1);
	}
	100% {
		-o-transform: scaleX(0.93333);
	}
}

@-ms-keyframes segment-scale-11 {
	0% {
		-ms-transform: scaleX(0.93333);
	}
	8.33333% {
		-ms-transform: scaleX(0.86667);
	}
	16.66667% {
		-ms-transform: scaleX(0.8);
	}
	25% {
		-ms-transform: scaleX(0.73333);
	}
	33.33333% {
		-ms-transform: scaleX(0.66667);
	}
	41.66667% {
		-ms-transform: scaleX(0.6);
	}
	50% {
		-ms-transform: scaleX(0.53333);
	}
	58.33333% {
		-ms-transform: scaleX(0.46667);
	}
	66.66667% {
		-ms-transform: scaleX(0.4);
	}
	75% {
		-ms-transform: scaleX(0.33333);
	}
	83.33333% {
		-ms-transform: scaleX(0.26667);
	}
	91.66667% {
		-ms-transform: scaleX(1);
	}
	100% {
		-ms-transform: scaleX(0.93333);
	}
}

@-webkit-keyframes segment-scale-11 {
	0% {
		-webkit-transform: scaleX(0.93333);
	}
	8.33333% {
		-webkit-transform: scaleX(0.86667);
	}
	16.66667% {
		-webkit-transform: scaleX(0.8);
	}
	25% {
		-webkit-transform: scaleX(0.73333);
	}
	33.33333% {
		-webkit-transform: scaleX(0.66667);
	}
	41.66667% {
		-webkit-transform: scaleX(0.6);
	}
	50% {
		-webkit-transform: scaleX(0.53333);
	}
	58.33333% {
		-webkit-transform: scaleX(0.46667);
	}
	66.66667% {
		-webkit-transform: scaleX(0.4);
	}
	75% {
		-webkit-transform: scaleX(0.33333);
	}
	83.33333% {
		-webkit-transform: scaleX(0.26667);
	}
	91.66667% {
		-webkit-transform: scaleX(1);
	}
	100% {
		-webkit-transform: scaleX(0.93333);
	}
}

@-moz-keyframes segment-scale-11 {
	0% {
		-moz-transform: scaleX(0.93333);
	}
	8.33333% {
		-moz-transform: scaleX(0.86667);
	}
	16.66667% {
		-moz-transform: scaleX(0.8);
	}
	25% {
		-moz-transform: scaleX(0.73333);
	}
	33.33333% {
		-moz-transform: scaleX(0.66667);
	}
	41.66667% {
		-moz-transform: scaleX(0.6);
	}
	50% {
		-moz-transform: scaleX(0.53333);
	}
	58.33333% {
		-moz-transform: scaleX(0.46667);
	}
	66.66667% {
		-moz-transform: scaleX(0.4);
	}
	75% {
		-moz-transform: scaleX(0.33333);
	}
	83.33333% {
		-moz-transform: scaleX(0.26667);
	}
	91.66667% {
		-moz-transform: scaleX(1);
	}
	100% {
		-moz-transform: scaleX(0.93333);
	}
}

@keyframes segment-scale-10 {
	0% {
		transform: scaleX(0.86667);
	}
	8.33333% {
		transform: scaleX(0.8);
	}
	16.66667% {
		transform: scaleX(0.73333);
	}
	25% {
		transform: scaleX(0.66667);
	}
	33.33333% {
		transform: scaleX(0.6);
	}
	41.66667% {
		transform: scaleX(0.53333);
	}
	50% {
		transform: scaleX(0.46667);
	}
	58.33333% {
		transform: scaleX(0.4);
	}
	66.66667% {
		transform: scaleX(0.33333);
	}
	75% {
		transform: scaleX(0.26667);
	}
	83.33333% {
		transform: scaleX(1);
	}
	91.66667% {
		transform: scaleX(0.93333);
	}
	100% {
		transform: scaleX(0.86667);
	}
}

@-o-keyframes segment-scale-10 {
	0% {
		-o-transform: scaleX(0.86667);
	}
	8.33333% {
		-o-transform: scaleX(0.8);
	}
	16.66667% {
		-o-transform: scaleX(0.73333);
	}
	25% {
		-o-transform: scaleX(0.66667);
	}
	33.33333% {
		-o-transform: scaleX(0.6);
	}
	41.66667% {
		-o-transform: scaleX(0.53333);
	}
	50% {
		-o-transform: scaleX(0.46667);
	}
	58.33333% {
		-o-transform: scaleX(0.4);
	}
	66.66667% {
		-o-transform: scaleX(0.33333);
	}
	75% {
		-o-transform: scaleX(0.26667);
	}
	83.33333% {
		-o-transform: scaleX(1);
	}
	91.66667% {
		-o-transform: scaleX(0.93333);
	}
	100% {
		-o-transform: scaleX(0.86667);
	}
}

@-ms-keyframes segment-scale-10 {
	0% {
		-ms-transform: scaleX(0.86667);
	}
	8.33333% {
		-ms-transform: scaleX(0.8);
	}
	16.66667% {
		-ms-transform: scaleX(0.73333);
	}
	25% {
		-ms-transform: scaleX(0.66667);
	}
	33.33333% {
		-ms-transform: scaleX(0.6);
	}
	41.66667% {
		-ms-transform: scaleX(0.53333);
	}
	50% {
		-ms-transform: scaleX(0.46667);
	}
	58.33333% {
		-ms-transform: scaleX(0.4);
	}
	66.66667% {
		-ms-transform: scaleX(0.33333);
	}
	75% {
		-ms-transform: scaleX(0.26667);
	}
	83.33333% {
		-ms-transform: scaleX(1);
	}
	91.66667% {
		-ms-transform: scaleX(0.93333);
	}
	100% {
		-ms-transform: scaleX(0.86667);
	}
}

@-webkit-keyframes segment-scale-10 {
	0% {
		-webkit-transform: scaleX(0.86667);
	}
	8.33333% {
		-webkit-transform: scaleX(0.8);
	}
	16.66667% {
		-webkit-transform: scaleX(0.73333);
	}
	25% {
		-webkit-transform: scaleX(0.66667);
	}
	33.33333% {
		-webkit-transform: scaleX(0.6);
	}
	41.66667% {
		-webkit-transform: scaleX(0.53333);
	}
	50% {
		-webkit-transform: scaleX(0.46667);
	}
	58.33333% {
		-webkit-transform: scaleX(0.4);
	}
	66.66667% {
		-webkit-transform: scaleX(0.33333);
	}
	75% {
		-webkit-transform: scaleX(0.26667);
	}
	83.33333% {
		-webkit-transform: scaleX(1);
	}
	91.66667% {
		-webkit-transform: scaleX(0.93333);
	}
	100% {
		-webkit-transform: scaleX(0.86667);
	}
}

@-moz-keyframes segment-scale-10 {
	0% {
		-moz-transform: scaleX(0.86667);
	}
	8.33333% {
		-moz-transform: scaleX(0.8);
	}
	16.66667% {
		-moz-transform: scaleX(0.73333);
	}
	25% {
		-moz-transform: scaleX(0.66667);
	}
	33.33333% {
		-moz-transform: scaleX(0.6);
	}
	41.66667% {
		-moz-transform: scaleX(0.53333);
	}
	50% {
		-moz-transform: scaleX(0.46667);
	}
	58.33333% {
		-moz-transform: scaleX(0.4);
	}
	66.66667% {
		-moz-transform: scaleX(0.33333);
	}
	75% {
		-moz-transform: scaleX(0.26667);
	}
	83.33333% {
		-moz-transform: scaleX(1);
	}
	91.66667% {
		-moz-transform: scaleX(0.93333);
	}
	100% {
		-moz-transform: scaleX(0.86667);
	}
}

@keyframes segment-scale-9 {
	0% {
		transform: scaleX(0.8);
	}
	8.33333% {
		transform: scaleX(0.73333);
	}
	16.66667% {
		transform: scaleX(0.66667);
	}
	25% {
		transform: scaleX(0.6);
	}
	33.33333% {
		transform: scaleX(0.53333);
	}
	41.66667% {
		transform: scaleX(0.46667);
	}
	50% {
		transform: scaleX(0.4);
	}
	58.33333% {
		transform: scaleX(0.33333);
	}
	66.66667% {
		transform: scaleX(0.26667);
	}
	75% {
		transform: scaleX(1);
	}
	83.33333% {
		transform: scaleX(0.93333);
	}
	91.66667% {
		transform: scaleX(0.86667);
	}
	100% {
		transform: scaleX(0.8);
	}
}

@-o-keyframes segment-scale-9 {
	0% {
		-o-transform: scaleX(0.8);
	}
	8.33333% {
		-o-transform: scaleX(0.73333);
	}
	16.66667% {
		-o-transform: scaleX(0.66667);
	}
	25% {
		-o-transform: scaleX(0.6);
	}
	33.33333% {
		-o-transform: scaleX(0.53333);
	}
	41.66667% {
		-o-transform: scaleX(0.46667);
	}
	50% {
		-o-transform: scaleX(0.4);
	}
	58.33333% {
		-o-transform: scaleX(0.33333);
	}
	66.66667% {
		-o-transform: scaleX(0.26667);
	}
	75% {
		-o-transform: scaleX(1);
	}
	83.33333% {
		-o-transform: scaleX(0.93333);
	}
	91.66667% {
		-o-transform: scaleX(0.86667);
	}
	100% {
		-o-transform: scaleX(0.8);
	}
}

@-ms-keyframes segment-scale-9 {
	0% {
		-ms-transform: scaleX(0.8);
	}
	8.33333% {
		-ms-transform: scaleX(0.73333);
	}
	16.66667% {
		-ms-transform: scaleX(0.66667);
	}
	25% {
		-ms-transform: scaleX(0.6);
	}
	33.33333% {
		-ms-transform: scaleX(0.53333);
	}
	41.66667% {
		-ms-transform: scaleX(0.46667);
	}
	50% {
		-ms-transform: scaleX(0.4);
	}
	58.33333% {
		-ms-transform: scaleX(0.33333);
	}
	66.66667% {
		-ms-transform: scaleX(0.26667);
	}
	75% {
		-ms-transform: scaleX(1);
	}
	83.33333% {
		-ms-transform: scaleX(0.93333);
	}
	91.66667% {
		-ms-transform: scaleX(0.86667);
	}
	100% {
		-ms-transform: scaleX(0.8);
	}
}

@-webkit-keyframes segment-scale-9 {
	0% {
		-webkit-transform: scaleX(0.8);
	}
	8.33333% {
		-webkit-transform: scaleX(0.73333);
	}
	16.66667% {
		-webkit-transform: scaleX(0.66667);
	}
	25% {
		-webkit-transform: scaleX(0.6);
	}
	33.33333% {
		-webkit-transform: scaleX(0.53333);
	}
	41.66667% {
		-webkit-transform: scaleX(0.46667);
	}
	50% {
		-webkit-transform: scaleX(0.4);
	}
	58.33333% {
		-webkit-transform: scaleX(0.33333);
	}
	66.66667% {
		-webkit-transform: scaleX(0.26667);
	}
	75% {
		-webkit-transform: scaleX(1);
	}
	83.33333% {
		-webkit-transform: scaleX(0.93333);
	}
	91.66667% {
		-webkit-transform: scaleX(0.86667);
	}
	100% {
		-webkit-transform: scaleX(0.8);
	}
}

@-moz-keyframes segment-scale-9 {
	0% {
		-moz-transform: scaleX(0.8);
	}
	8.33333% {
		-moz-transform: scaleX(0.73333);
	}
	16.66667% {
		-moz-transform: scaleX(0.66667);
	}
	25% {
		-moz-transform: scaleX(0.6);
	}
	33.33333% {
		-moz-transform: scaleX(0.53333);
	}
	41.66667% {
		-moz-transform: scaleX(0.46667);
	}
	50% {
		-moz-transform: scaleX(0.4);
	}
	58.33333% {
		-moz-transform: scaleX(0.33333);
	}
	66.66667% {
		-moz-transform: scaleX(0.26667);
	}
	75% {
		-moz-transform: scaleX(1);
	}
	83.33333% {
		-moz-transform: scaleX(0.93333);
	}
	91.66667% {
		-moz-transform: scaleX(0.86667);
	}
	100% {
		-moz-transform: scaleX(0.8);
	}
}

@keyframes segment-scale-8 {
	0% {
		transform: scaleX(0.73333);
	}
	8.33333% {
		transform: scaleX(0.66667);
	}
	16.66667% {
		transform: scaleX(0.6);
	}
	25% {
		transform: scaleX(0.53333);
	}
	33.33333% {
		transform: scaleX(0.46667);
	}
	41.66667% {
		transform: scaleX(0.4);
	}
	50% {
		transform: scaleX(0.33333);
	}
	58.33333% {
		transform: scaleX(0.26667);
	}
	66.66667% {
		transform: scaleX(1);
	}
	75% {
		transform: scaleX(0.93333);
	}
	83.33333% {
		transform: scaleX(0.86667);
	}
	91.66667% {
		transform: scaleX(0.8);
	}
	100% {
		transform: scaleX(0.73333);
	}
}

@-o-keyframes segment-scale-8 {
	0% {
		-o-transform: scaleX(0.73333);
	}
	8.33333% {
		-o-transform: scaleX(0.66667);
	}
	16.66667% {
		-o-transform: scaleX(0.6);
	}
	25% {
		-o-transform: scaleX(0.53333);
	}
	33.33333% {
		-o-transform: scaleX(0.46667);
	}
	41.66667% {
		-o-transform: scaleX(0.4);
	}
	50% {
		-o-transform: scaleX(0.33333);
	}
	58.33333% {
		-o-transform: scaleX(0.26667);
	}
	66.66667% {
		-o-transform: scaleX(1);
	}
	75% {
		-o-transform: scaleX(0.93333);
	}
	83.33333% {
		-o-transform: scaleX(0.86667);
	}
	91.66667% {
		-o-transform: scaleX(0.8);
	}
	100% {
		-o-transform: scaleX(0.73333);
	}
}

@-ms-keyframes segment-scale-8 {
	0% {
		-ms-transform: scaleX(0.73333);
	}
	8.33333% {
		-ms-transform: scaleX(0.66667);
	}
	16.66667% {
		-ms-transform: scaleX(0.6);
	}
	25% {
		-ms-transform: scaleX(0.53333);
	}
	33.33333% {
		-ms-transform: scaleX(0.46667);
	}
	41.66667% {
		-ms-transform: scaleX(0.4);
	}
	50% {
		-ms-transform: scaleX(0.33333);
	}
	58.33333% {
		-ms-transform: scaleX(0.26667);
	}
	66.66667% {
		-ms-transform: scaleX(1);
	}
	75% {
		-ms-transform: scaleX(0.93333);
	}
	83.33333% {
		-ms-transform: scaleX(0.86667);
	}
	91.66667% {
		-ms-transform: scaleX(0.8);
	}
	100% {
		-ms-transform: scaleX(0.73333);
	}
}

@-webkit-keyframes segment-scale-8 {
	0% {
		-webkit-transform: scaleX(0.73333);
	}
	8.33333% {
		-webkit-transform: scaleX(0.66667);
	}
	16.66667% {
		-webkit-transform: scaleX(0.6);
	}
	25% {
		-webkit-transform: scaleX(0.53333);
	}
	33.33333% {
		-webkit-transform: scaleX(0.46667);
	}
	41.66667% {
		-webkit-transform: scaleX(0.4);
	}
	50% {
		-webkit-transform: scaleX(0.33333);
	}
	58.33333% {
		-webkit-transform: scaleX(0.26667);
	}
	66.66667% {
		-webkit-transform: scaleX(1);
	}
	75% {
		-webkit-transform: scaleX(0.93333);
	}
	83.33333% {
		-webkit-transform: scaleX(0.86667);
	}
	91.66667% {
		-webkit-transform: scaleX(0.8);
	}
	100% {
		-webkit-transform: scaleX(0.73333);
	}
}

@-moz-keyframes segment-scale-8 {
	0% {
		-moz-transform: scaleX(0.73333);
	}
	8.33333% {
		-moz-transform: scaleX(0.66667);
	}
	16.66667% {
		-moz-transform: scaleX(0.6);
	}
	25% {
		-moz-transform: scaleX(0.53333);
	}
	33.33333% {
		-moz-transform: scaleX(0.46667);
	}
	41.66667% {
		-moz-transform: scaleX(0.4);
	}
	50% {
		-moz-transform: scaleX(0.33333);
	}
	58.33333% {
		-moz-transform: scaleX(0.26667);
	}
	66.66667% {
		-moz-transform: scaleX(1);
	}
	75% {
		-moz-transform: scaleX(0.93333);
	}
	83.33333% {
		-moz-transform: scaleX(0.86667);
	}
	91.66667% {
		-moz-transform: scaleX(0.8);
	}
	100% {
		-moz-transform: scaleX(0.73333);
	}
}

@keyframes segment-scale-7 {
	0% {
		transform: scaleX(0.66667);
	}
	8.33333% {
		transform: scaleX(0.6);
	}
	16.66667% {
		transform: scaleX(0.53333);
	}
	25% {
		transform: scaleX(0.46667);
	}
	33.33333% {
		transform: scaleX(0.4);
	}
	41.66667% {
		transform: scaleX(0.33333);
	}
	50% {
		transform: scaleX(0.26667);
	}
	58.33333% {
		transform: scaleX(1);
	}
	66.66667% {
		transform: scaleX(0.93333);
	}
	75% {
		transform: scaleX(0.86667);
	}
	83.33333% {
		transform: scaleX(0.8);
	}
	91.66667% {
		transform: scaleX(0.73333);
	}
	100% {
		transform: scaleX(0.66667);
	}
}

@-o-keyframes segment-scale-7 {
	0% {
		-o-transform: scaleX(0.66667);
	}
	8.33333% {
		-o-transform: scaleX(0.6);
	}
	16.66667% {
		-o-transform: scaleX(0.53333);
	}
	25% {
		-o-transform: scaleX(0.46667);
	}
	33.33333% {
		-o-transform: scaleX(0.4);
	}
	41.66667% {
		-o-transform: scaleX(0.33333);
	}
	50% {
		-o-transform: scaleX(0.26667);
	}
	58.33333% {
		-o-transform: scaleX(1);
	}
	66.66667% {
		-o-transform: scaleX(0.93333);
	}
	75% {
		-o-transform: scaleX(0.86667);
	}
	83.33333% {
		-o-transform: scaleX(0.8);
	}
	91.66667% {
		-o-transform: scaleX(0.73333);
	}
	100% {
		-o-transform: scaleX(0.66667);
	}
}

@-ms-keyframes segment-scale-7 {
	0% {
		-ms-transform: scaleX(0.66667);
	}
	8.33333% {
		-ms-transform: scaleX(0.6);
	}
	16.66667% {
		-ms-transform: scaleX(0.53333);
	}
	25% {
		-ms-transform: scaleX(0.46667);
	}
	33.33333% {
		-ms-transform: scaleX(0.4);
	}
	41.66667% {
		-ms-transform: scaleX(0.33333);
	}
	50% {
		-ms-transform: scaleX(0.26667);
	}
	58.33333% {
		-ms-transform: scaleX(1);
	}
	66.66667% {
		-ms-transform: scaleX(0.93333);
	}
	75% {
		-ms-transform: scaleX(0.86667);
	}
	83.33333% {
		-ms-transform: scaleX(0.8);
	}
	91.66667% {
		-ms-transform: scaleX(0.73333);
	}
	100% {
		-ms-transform: scaleX(0.66667);
	}
}

@-webkit-keyframes segment-scale-7 {
	0% {
		-webkit-transform: scaleX(0.66667);
	}
	8.33333% {
		-webkit-transform: scaleX(0.6);
	}
	16.66667% {
		-webkit-transform: scaleX(0.53333);
	}
	25% {
		-webkit-transform: scaleX(0.46667);
	}
	33.33333% {
		-webkit-transform: scaleX(0.4);
	}
	41.66667% {
		-webkit-transform: scaleX(0.33333);
	}
	50% {
		-webkit-transform: scaleX(0.26667);
	}
	58.33333% {
		-webkit-transform: scaleX(1);
	}
	66.66667% {
		-webkit-transform: scaleX(0.93333);
	}
	75% {
		-webkit-transform: scaleX(0.86667);
	}
	83.33333% {
		-webkit-transform: scaleX(0.8);
	}
	91.66667% {
		-webkit-transform: scaleX(0.73333);
	}
	100% {
		-webkit-transform: scaleX(0.66667);
	}
}

@-moz-keyframes segment-scale-7 {
	0% {
		-moz-transform: scaleX(0.66667);
	}
	8.33333% {
		-moz-transform: scaleX(0.6);
	}
	16.66667% {
		-moz-transform: scaleX(0.53333);
	}
	25% {
		-moz-transform: scaleX(0.46667);
	}
	33.33333% {
		-moz-transform: scaleX(0.4);
	}
	41.66667% {
		-moz-transform: scaleX(0.33333);
	}
	50% {
		-moz-transform: scaleX(0.26667);
	}
	58.33333% {
		-moz-transform: scaleX(1);
	}
	66.66667% {
		-moz-transform: scaleX(0.93333);
	}
	75% {
		-moz-transform: scaleX(0.86667);
	}
	83.33333% {
		-moz-transform: scaleX(0.8);
	}
	91.66667% {
		-moz-transform: scaleX(0.73333);
	}
	100% {
		-moz-transform: scaleX(0.66667);
	}
}

@keyframes segment-scale-6 {
	0% {
		transform: scaleX(0.6);
	}
	8.33333% {
		transform: scaleX(0.53333);
	}
	16.66667% {
		transform: scaleX(0.46667);
	}
	25% {
		transform: scaleX(0.4);
	}
	33.33333% {
		transform: scaleX(0.33333);
	}
	41.66667% {
		transform: scaleX(0.26667);
	}
	50% {
		transform: scaleX(1);
	}
	58.33333% {
		transform: scaleX(0.93333);
	}
	66.66667% {
		transform: scaleX(0.86667);
	}
	75% {
		transform: scaleX(0.8);
	}
	83.33333% {
		transform: scaleX(0.73333);
	}
	91.66667% {
		transform: scaleX(0.66667);
	}
	100% {
		transform: scaleX(0.6);
	}
}

@-o-keyframes segment-scale-6 {
	0% {
		-o-transform: scaleX(0.6);
	}
	8.33333% {
		-o-transform: scaleX(0.53333);
	}
	16.66667% {
		-o-transform: scaleX(0.46667);
	}
	25% {
		-o-transform: scaleX(0.4);
	}
	33.33333% {
		-o-transform: scaleX(0.33333);
	}
	41.66667% {
		-o-transform: scaleX(0.26667);
	}
	50% {
		-o-transform: scaleX(1);
	}
	58.33333% {
		-o-transform: scaleX(0.93333);
	}
	66.66667% {
		-o-transform: scaleX(0.86667);
	}
	75% {
		-o-transform: scaleX(0.8);
	}
	83.33333% {
		-o-transform: scaleX(0.73333);
	}
	91.66667% {
		-o-transform: scaleX(0.66667);
	}
	100% {
		-o-transform: scaleX(0.6);
	}
}

@-ms-keyframes segment-scale-6 {
	0% {
		-ms-transform: scaleX(0.6);
	}
	8.33333% {
		-ms-transform: scaleX(0.53333);
	}
	16.66667% {
		-ms-transform: scaleX(0.46667);
	}
	25% {
		-ms-transform: scaleX(0.4);
	}
	33.33333% {
		-ms-transform: scaleX(0.33333);
	}
	41.66667% {
		-ms-transform: scaleX(0.26667);
	}
	50% {
		-ms-transform: scaleX(1);
	}
	58.33333% {
		-ms-transform: scaleX(0.93333);
	}
	66.66667% {
		-ms-transform: scaleX(0.86667);
	}
	75% {
		-ms-transform: scaleX(0.8);
	}
	83.33333% {
		-ms-transform: scaleX(0.73333);
	}
	91.66667% {
		-ms-transform: scaleX(0.66667);
	}
	100% {
		-ms-transform: scaleX(0.6);
	}
}

@-webkit-keyframes segment-scale-6 {
	0% {
		-webkit-transform: scaleX(0.6);
	}
	8.33333% {
		-webkit-transform: scaleX(0.53333);
	}
	16.66667% {
		-webkit-transform: scaleX(0.46667);
	}
	25% {
		-webkit-transform: scaleX(0.4);
	}
	33.33333% {
		-webkit-transform: scaleX(0.33333);
	}
	41.66667% {
		-webkit-transform: scaleX(0.26667);
	}
	50% {
		-webkit-transform: scaleX(1);
	}
	58.33333% {
		-webkit-transform: scaleX(0.93333);
	}
	66.66667% {
		-webkit-transform: scaleX(0.86667);
	}
	75% {
		-webkit-transform: scaleX(0.8);
	}
	83.33333% {
		-webkit-transform: scaleX(0.73333);
	}
	91.66667% {
		-webkit-transform: scaleX(0.66667);
	}
	100% {
		-webkit-transform: scaleX(0.6);
	}
}

@-moz-keyframes segment-scale-6 {
	0% {
		-moz-transform: scaleX(0.6);
	}
	8.33333% {
		-moz-transform: scaleX(0.53333);
	}
	16.66667% {
		-moz-transform: scaleX(0.46667);
	}
	25% {
		-moz-transform: scaleX(0.4);
	}
	33.33333% {
		-moz-transform: scaleX(0.33333);
	}
	41.66667% {
		-moz-transform: scaleX(0.26667);
	}
	50% {
		-moz-transform: scaleX(1);
	}
	58.33333% {
		-moz-transform: scaleX(0.93333);
	}
	66.66667% {
		-moz-transform: scaleX(0.86667);
	}
	75% {
		-moz-transform: scaleX(0.8);
	}
	83.33333% {
		-moz-transform: scaleX(0.73333);
	}
	91.66667% {
		-moz-transform: scaleX(0.66667);
	}
	100% {
		-moz-transform: scaleX(0.6);
	}
}

@keyframes segment-scale-5 {
	0% {
		transform: scaleX(0.53333);
	}
	8.33333% {
		transform: scaleX(0.46667);
	}
	16.66667% {
		transform: scaleX(0.4);
	}
	25% {
		transform: scaleX(0.33333);
	}
	33.33333% {
		transform: scaleX(0.26667);
	}
	41.66667% {
		transform: scaleX(1);
	}
	50% {
		transform: scaleX(0.93333);
	}
	58.33333% {
		transform: scaleX(0.86667);
	}
	66.66667% {
		transform: scaleX(0.8);
	}
	75% {
		transform: scaleX(0.73333);
	}
	83.33333% {
		transform: scaleX(0.66667);
	}
	91.66667% {
		transform: scaleX(0.6);
	}
	100% {
		transform: scaleX(0.53333);
	}
}

@-o-keyframes segment-scale-5 {
	0% {
		-o-transform: scaleX(0.53333);
	}
	8.33333% {
		-o-transform: scaleX(0.46667);
	}
	16.66667% {
		-o-transform: scaleX(0.4);
	}
	25% {
		-o-transform: scaleX(0.33333);
	}
	33.33333% {
		-o-transform: scaleX(0.26667);
	}
	41.66667% {
		-o-transform: scaleX(1);
	}
	50% {
		-o-transform: scaleX(0.93333);
	}
	58.33333% {
		-o-transform: scaleX(0.86667);
	}
	66.66667% {
		-o-transform: scaleX(0.8);
	}
	75% {
		-o-transform: scaleX(0.73333);
	}
	83.33333% {
		-o-transform: scaleX(0.66667);
	}
	91.66667% {
		-o-transform: scaleX(0.6);
	}
	100% {
		-o-transform: scaleX(0.53333);
	}
}

@-ms-keyframes segment-scale-5 {
	0% {
		-ms-transform: scaleX(0.53333);
	}
	8.33333% {
		-ms-transform: scaleX(0.46667);
	}
	16.66667% {
		-ms-transform: scaleX(0.4);
	}
	25% {
		-ms-transform: scaleX(0.33333);
	}
	33.33333% {
		-ms-transform: scaleX(0.26667);
	}
	41.66667% {
		-ms-transform: scaleX(1);
	}
	50% {
		-ms-transform: scaleX(0.93333);
	}
	58.33333% {
		-ms-transform: scaleX(0.86667);
	}
	66.66667% {
		-ms-transform: scaleX(0.8);
	}
	75% {
		-ms-transform: scaleX(0.73333);
	}
	83.33333% {
		-ms-transform: scaleX(0.66667);
	}
	91.66667% {
		-ms-transform: scaleX(0.6);
	}
	100% {
		-ms-transform: scaleX(0.53333);
	}
}

@-webkit-keyframes segment-scale-5 {
	0% {
		-webkit-transform: scaleX(0.53333);
	}
	8.33333% {
		-webkit-transform: scaleX(0.46667);
	}
	16.66667% {
		-webkit-transform: scaleX(0.4);
	}
	25% {
		-webkit-transform: scaleX(0.33333);
	}
	33.33333% {
		-webkit-transform: scaleX(0.26667);
	}
	41.66667% {
		-webkit-transform: scaleX(1);
	}
	50% {
		-webkit-transform: scaleX(0.93333);
	}
	58.33333% {
		-webkit-transform: scaleX(0.86667);
	}
	66.66667% {
		-webkit-transform: scaleX(0.8);
	}
	75% {
		-webkit-transform: scaleX(0.73333);
	}
	83.33333% {
		-webkit-transform: scaleX(0.66667);
	}
	91.66667% {
		-webkit-transform: scaleX(0.6);
	}
	100% {
		-webkit-transform: scaleX(0.53333);
	}
}

@-moz-keyframes segment-scale-5 {
	0% {
		-moz-transform: scaleX(0.53333);
	}
	8.33333% {
		-moz-transform: scaleX(0.46667);
	}
	16.66667% {
		-moz-transform: scaleX(0.4);
	}
	25% {
		-moz-transform: scaleX(0.33333);
	}
	33.33333% {
		-moz-transform: scaleX(0.26667);
	}
	41.66667% {
		-moz-transform: scaleX(1);
	}
	50% {
		-moz-transform: scaleX(0.93333);
	}
	58.33333% {
		-moz-transform: scaleX(0.86667);
	}
	66.66667% {
		-moz-transform: scaleX(0.8);
	}
	75% {
		-moz-transform: scaleX(0.73333);
	}
	83.33333% {
		-moz-transform: scaleX(0.66667);
	}
	91.66667% {
		-moz-transform: scaleX(0.6);
	}
	100% {
		-moz-transform: scaleX(0.53333);
	}
}

@keyframes segment-scale-4 {
	0% {
		transform: scaleX(0.46667);
	}
	8.33333% {
		transform: scaleX(0.4);
	}
	16.66667% {
		transform: scaleX(0.33333);
	}
	25% {
		transform: scaleX(0.26667);
	}
	33.33333% {
		transform: scaleX(1);
	}
	41.66667% {
		transform: scaleX(0.93333);
	}
	50% {
		transform: scaleX(0.86667);
	}
	58.33333% {
		transform: scaleX(0.8);
	}
	66.66667% {
		transform: scaleX(0.73333);
	}
	75% {
		transform: scaleX(0.66667);
	}
	83.33333% {
		transform: scaleX(0.6);
	}
	91.66667% {
		transform: scaleX(0.53333);
	}
	100% {
		transform: scaleX(0.46667);
	}
}

@-o-keyframes segment-scale-4 {
	0% {
		-o-transform: scaleX(0.46667);
	}
	8.33333% {
		-o-transform: scaleX(0.4);
	}
	16.66667% {
		-o-transform: scaleX(0.33333);
	}
	25% {
		-o-transform: scaleX(0.26667);
	}
	33.33333% {
		-o-transform: scaleX(1);
	}
	41.66667% {
		-o-transform: scaleX(0.93333);
	}
	50% {
		-o-transform: scaleX(0.86667);
	}
	58.33333% {
		-o-transform: scaleX(0.8);
	}
	66.66667% {
		-o-transform: scaleX(0.73333);
	}
	75% {
		-o-transform: scaleX(0.66667);
	}
	83.33333% {
		-o-transform: scaleX(0.6);
	}
	91.66667% {
		-o-transform: scaleX(0.53333);
	}
	100% {
		-o-transform: scaleX(0.46667);
	}
}

@-ms-keyframes segment-scale-4 {
	0% {
		-ms-transform: scaleX(0.46667);
	}
	8.33333% {
		-ms-transform: scaleX(0.4);
	}
	16.66667% {
		-ms-transform: scaleX(0.33333);
	}
	25% {
		-ms-transform: scaleX(0.26667);
	}
	33.33333% {
		-ms-transform: scaleX(1);
	}
	41.66667% {
		-ms-transform: scaleX(0.93333);
	}
	50% {
		-ms-transform: scaleX(0.86667);
	}
	58.33333% {
		-ms-transform: scaleX(0.8);
	}
	66.66667% {
		-ms-transform: scaleX(0.73333);
	}
	75% {
		-ms-transform: scaleX(0.66667);
	}
	83.33333% {
		-ms-transform: scaleX(0.6);
	}
	91.66667% {
		-ms-transform: scaleX(0.53333);
	}
	100% {
		-ms-transform: scaleX(0.46667);
	}
}

@-webkit-keyframes segment-scale-4 {
	0% {
		-webkit-transform: scaleX(0.46667);
	}
	8.33333% {
		-webkit-transform: scaleX(0.4);
	}
	16.66667% {
		-webkit-transform: scaleX(0.33333);
	}
	25% {
		-webkit-transform: scaleX(0.26667);
	}
	33.33333% {
		-webkit-transform: scaleX(1);
	}
	41.66667% {
		-webkit-transform: scaleX(0.93333);
	}
	50% {
		-webkit-transform: scaleX(0.86667);
	}
	58.33333% {
		-webkit-transform: scaleX(0.8);
	}
	66.66667% {
		-webkit-transform: scaleX(0.73333);
	}
	75% {
		-webkit-transform: scaleX(0.66667);
	}
	83.33333% {
		-webkit-transform: scaleX(0.6);
	}
	91.66667% {
		-webkit-transform: scaleX(0.53333);
	}
	100% {
		-webkit-transform: scaleX(0.46667);
	}
}

@-moz-keyframes segment-scale-4 {
	0% {
		-moz-transform: scaleX(0.46667);
	}
	8.33333% {
		-moz-transform: scaleX(0.4);
	}
	16.66667% {
		-moz-transform: scaleX(0.33333);
	}
	25% {
		-moz-transform: scaleX(0.26667);
	}
	33.33333% {
		-moz-transform: scaleX(1);
	}
	41.66667% {
		-moz-transform: scaleX(0.93333);
	}
	50% {
		-moz-transform: scaleX(0.86667);
	}
	58.33333% {
		-moz-transform: scaleX(0.8);
	}
	66.66667% {
		-moz-transform: scaleX(0.73333);
	}
	75% {
		-moz-transform: scaleX(0.66667);
	}
	83.33333% {
		-moz-transform: scaleX(0.6);
	}
	91.66667% {
		-moz-transform: scaleX(0.53333);
	}
	100% {
		-moz-transform: scaleX(0.46667);
	}
}

@keyframes segment-scale-3 {
	0% {
		transform: scaleX(0.4);
	}
	8.33333% {
		transform: scaleX(0.33333);
	}
	16.66667% {
		transform: scaleX(0.26667);
	}
	25% {
		transform: scaleX(1);
	}
	33.33333% {
		transform: scaleX(0.93333);
	}
	41.66667% {
		transform: scaleX(0.86667);
	}
	50% {
		transform: scaleX(0.8);
	}
	58.33333% {
		transform: scaleX(0.73333);
	}
	66.66667% {
		transform: scaleX(0.66667);
	}
	75% {
		transform: scaleX(0.6);
	}
	83.33333% {
		transform: scaleX(0.53333);
	}
	91.66667% {
		transform: scaleX(0.46667);
	}
	100% {
		transform: scaleX(0.4);
	}
}

@-o-keyframes segment-scale-3 {
	0% {
		-o-transform: scaleX(0.4);
	}
	8.33333% {
		-o-transform: scaleX(0.33333);
	}
	16.66667% {
		-o-transform: scaleX(0.26667);
	}
	25% {
		-o-transform: scaleX(1);
	}
	33.33333% {
		-o-transform: scaleX(0.93333);
	}
	41.66667% {
		-o-transform: scaleX(0.86667);
	}
	50% {
		-o-transform: scaleX(0.8);
	}
	58.33333% {
		-o-transform: scaleX(0.73333);
	}
	66.66667% {
		-o-transform: scaleX(0.66667);
	}
	75% {
		-o-transform: scaleX(0.6);
	}
	83.33333% {
		-o-transform: scaleX(0.53333);
	}
	91.66667% {
		-o-transform: scaleX(0.46667);
	}
	100% {
		-o-transform: scaleX(0.4);
	}
}

@-ms-keyframes segment-scale-3 {
	0% {
		-ms-transform: scaleX(0.4);
	}
	8.33333% {
		-ms-transform: scaleX(0.33333);
	}
	16.66667% {
		-ms-transform: scaleX(0.26667);
	}
	25% {
		-ms-transform: scaleX(1);
	}
	33.33333% {
		-ms-transform: scaleX(0.93333);
	}
	41.66667% {
		-ms-transform: scaleX(0.86667);
	}
	50% {
		-ms-transform: scaleX(0.8);
	}
	58.33333% {
		-ms-transform: scaleX(0.73333);
	}
	66.66667% {
		-ms-transform: scaleX(0.66667);
	}
	75% {
		-ms-transform: scaleX(0.6);
	}
	83.33333% {
		-ms-transform: scaleX(0.53333);
	}
	91.66667% {
		-ms-transform: scaleX(0.46667);
	}
	100% {
		-ms-transform: scaleX(0.4);
	}
}

@-webkit-keyframes segment-scale-3 {
	0% {
		-webkit-transform: scaleX(0.4);
	}
	8.33333% {
		-webkit-transform: scaleX(0.33333);
	}
	16.66667% {
		-webkit-transform: scaleX(0.26667);
	}
	25% {
		-webkit-transform: scaleX(1);
	}
	33.33333% {
		-webkit-transform: scaleX(0.93333);
	}
	41.66667% {
		-webkit-transform: scaleX(0.86667);
	}
	50% {
		-webkit-transform: scaleX(0.8);
	}
	58.33333% {
		-webkit-transform: scaleX(0.73333);
	}
	66.66667% {
		-webkit-transform: scaleX(0.66667);
	}
	75% {
		-webkit-transform: scaleX(0.6);
	}
	83.33333% {
		-webkit-transform: scaleX(0.53333);
	}
	91.66667% {
		-webkit-transform: scaleX(0.46667);
	}
	100% {
		-webkit-transform: scaleX(0.4);
	}
}

@-moz-keyframes segment-scale-3 {
	0% {
		-moz-transform: scaleX(0.4);
	}
	8.33333% {
		-moz-transform: scaleX(0.33333);
	}
	16.66667% {
		-moz-transform: scaleX(0.26667);
	}
	25% {
		-moz-transform: scaleX(1);
	}
	33.33333% {
		-moz-transform: scaleX(0.93333);
	}
	41.66667% {
		-moz-transform: scaleX(0.86667);
	}
	50% {
		-moz-transform: scaleX(0.8);
	}
	58.33333% {
		-moz-transform: scaleX(0.73333);
	}
	66.66667% {
		-moz-transform: scaleX(0.66667);
	}
	75% {
		-moz-transform: scaleX(0.6);
	}
	83.33333% {
		-moz-transform: scaleX(0.53333);
	}
	91.66667% {
		-moz-transform: scaleX(0.46667);
	}
	100% {
		-moz-transform: scaleX(0.4);
	}
}

@keyframes segment-scale-2 {
	0% {
		transform: scaleX(0.33333);
	}
	8.33333% {
		transform: scaleX(0.26667);
	}
	16.66667% {
		transform: scaleX(1);
	}
	25% {
		transform: scaleX(0.93333);
	}
	33.33333% {
		transform: scaleX(0.86667);
	}
	41.66667% {
		transform: scaleX(0.8);
	}
	50% {
		transform: scaleX(0.73333);
	}
	58.33333% {
		transform: scaleX(0.66667);
	}
	66.66667% {
		transform: scaleX(0.6);
	}
	75% {
		transform: scaleX(0.53333);
	}
	83.33333% {
		transform: scaleX(0.46667);
	}
	91.66667% {
		transform: scaleX(0.4);
	}
	100% {
		transform: scaleX(0.33333);
	}
}

@-o-keyframes segment-scale-2 {
	0% {
		-o-transform: scaleX(0.33333);
	}
	8.33333% {
		-o-transform: scaleX(0.26667);
	}
	16.66667% {
		-o-transform: scaleX(1);
	}
	25% {
		-o-transform: scaleX(0.93333);
	}
	33.33333% {
		-o-transform: scaleX(0.86667);
	}
	41.66667% {
		-o-transform: scaleX(0.8);
	}
	50% {
		-o-transform: scaleX(0.73333);
	}
	58.33333% {
		-o-transform: scaleX(0.66667);
	}
	66.66667% {
		-o-transform: scaleX(0.6);
	}
	75% {
		-o-transform: scaleX(0.53333);
	}
	83.33333% {
		-o-transform: scaleX(0.46667);
	}
	91.66667% {
		-o-transform: scaleX(0.4);
	}
	100% {
		-o-transform: scaleX(0.33333);
	}
}

@-ms-keyframes segment-scale-2 {
	0% {
		-ms-transform: scaleX(0.33333);
	}
	8.33333% {
		-ms-transform: scaleX(0.26667);
	}
	16.66667% {
		-ms-transform: scaleX(1);
	}
	25% {
		-ms-transform: scaleX(0.93333);
	}
	33.33333% {
		-ms-transform: scaleX(0.86667);
	}
	41.66667% {
		-ms-transform: scaleX(0.8);
	}
	50% {
		-ms-transform: scaleX(0.73333);
	}
	58.33333% {
		-ms-transform: scaleX(0.66667);
	}
	66.66667% {
		-ms-transform: scaleX(0.6);
	}
	75% {
		-ms-transform: scaleX(0.53333);
	}
	83.33333% {
		-ms-transform: scaleX(0.46667);
	}
	91.66667% {
		-ms-transform: scaleX(0.4);
	}
	100% {
		-ms-transform: scaleX(0.33333);
	}
}

@-webkit-keyframes segment-scale-2 {
	0% {
		-webkit-transform: scaleX(0.33333);
	}
	8.33333% {
		-webkit-transform: scaleX(0.26667);
	}
	16.66667% {
		-webkit-transform: scaleX(1);
	}
	25% {
		-webkit-transform: scaleX(0.93333);
	}
	33.33333% {
		-webkit-transform: scaleX(0.86667);
	}
	41.66667% {
		-webkit-transform: scaleX(0.8);
	}
	50% {
		-webkit-transform: scaleX(0.73333);
	}
	58.33333% {
		-webkit-transform: scaleX(0.66667);
	}
	66.66667% {
		-webkit-transform: scaleX(0.6);
	}
	75% {
		-webkit-transform: scaleX(0.53333);
	}
	83.33333% {
		-webkit-transform: scaleX(0.46667);
	}
	91.66667% {
		-webkit-transform: scaleX(0.4);
	}
	100% {
		-webkit-transform: scaleX(0.33333);
	}
}

@-moz-keyframes segment-scale-2 {
	0% {
		-moz-transform: scaleX(0.33333);
	}
	8.33333% {
		-moz-transform: scaleX(0.26667);
	}
	16.66667% {
		-moz-transform: scaleX(1);
	}
	25% {
		-moz-transform: scaleX(0.93333);
	}
	33.33333% {
		-moz-transform: scaleX(0.86667);
	}
	41.66667% {
		-moz-transform: scaleX(0.8);
	}
	50% {
		-moz-transform: scaleX(0.73333);
	}
	58.33333% {
		-moz-transform: scaleX(0.66667);
	}
	66.66667% {
		-moz-transform: scaleX(0.6);
	}
	75% {
		-moz-transform: scaleX(0.53333);
	}
	83.33333% {
		-moz-transform: scaleX(0.46667);
	}
	91.66667% {
		-moz-transform: scaleX(0.4);
	}
	100% {
		-moz-transform: scaleX(0.33333);
	}
}

@keyframes segment-scale-1 {
	0% {
		transform: scaleX(0.26667);
	}
	8.33333% {
		transform: scaleX(1);
	}
	16.66667% {
		transform: scaleX(0.93333);
	}
	25% {
		transform: scaleX(0.86667);
	}
	33.33333% {
		transform: scaleX(0.8);
	}
	41.66667% {
		transform: scaleX(0.73333);
	}
	50% {
		transform: scaleX(0.66667);
	}
	58.33333% {
		transform: scaleX(0.6);
	}
	66.66667% {
		transform: scaleX(0.53333);
	}
	75% {
		transform: scaleX(0.46667);
	}
	83.33333% {
		transform: scaleX(0.4);
	}
	91.66667% {
		transform: scaleX(0.33333);
	}
	100% {
		transform: scaleX(0.26667);
	}
}

@-o-keyframes segment-scale-1 {
	0% {
		-o-transform: scaleX(0.26667);
	}
	8.33333% {
		-o-transform: scaleX(1);
	}
	16.66667% {
		-o-transform: scaleX(0.93333);
	}
	25% {
		-o-transform: scaleX(0.86667);
	}
	33.33333% {
		-o-transform: scaleX(0.8);
	}
	41.66667% {
		-o-transform: scaleX(0.73333);
	}
	50% {
		-o-transform: scaleX(0.66667);
	}
	58.33333% {
		-o-transform: scaleX(0.6);
	}
	66.66667% {
		-o-transform: scaleX(0.53333);
	}
	75% {
		-o-transform: scaleX(0.46667);
	}
	83.33333% {
		-o-transform: scaleX(0.4);
	}
	91.66667% {
		-o-transform: scaleX(0.33333);
	}
	100% {
		-o-transform: scaleX(0.26667);
	}
}

@-ms-keyframes segment-scale-1 {
	0% {
		-ms-transform: scaleX(0.26667);
	}
	8.33333% {
		-ms-transform: scaleX(1);
	}
	16.66667% {
		-ms-transform: scaleX(0.93333);
	}
	25% {
		-ms-transform: scaleX(0.86667);
	}
	33.33333% {
		-ms-transform: scaleX(0.8);
	}
	41.66667% {
		-ms-transform: scaleX(0.73333);
	}
	50% {
		-ms-transform: scaleX(0.66667);
	}
	58.33333% {
		-ms-transform: scaleX(0.6);
	}
	66.66667% {
		-ms-transform: scaleX(0.53333);
	}
	75% {
		-ms-transform: scaleX(0.46667);
	}
	83.33333% {
		-ms-transform: scaleX(0.4);
	}
	91.66667% {
		-ms-transform: scaleX(0.33333);
	}
	100% {
		-ms-transform: scaleX(0.26667);
	}
}

@-webkit-keyframes segment-scale-1 {
	0% {
		-webkit-transform: scaleX(0.26667);
	}
	8.33333% {
		-webkit-transform: scaleX(1);
	}
	16.66667% {
		-webkit-transform: scaleX(0.93333);
	}
	25% {
		-webkit-transform: scaleX(0.86667);
	}
	33.33333% {
		-webkit-transform: scaleX(0.8);
	}
	41.66667% {
		-webkit-transform: scaleX(0.73333);
	}
	50% {
		-webkit-transform: scaleX(0.66667);
	}
	58.33333% {
		-webkit-transform: scaleX(0.6);
	}
	66.66667% {
		-webkit-transform: scaleX(0.53333);
	}
	75% {
		-webkit-transform: scaleX(0.46667);
	}
	83.33333% {
		-webkit-transform: scaleX(0.4);
	}
	91.66667% {
		-webkit-transform: scaleX(0.33333);
	}
	100% {
		-webkit-transform: scaleX(0.26667);
	}
}

@-moz-keyframes segment-scale-1 {
	0% {
		-moz-transform: scaleX(0.26667);
	}
	8.33333% {
		-moz-transform: scaleX(1);
	}
	16.66667% {
		-moz-transform: scaleX(0.93333);
	}
	25% {
		-moz-transform: scaleX(0.86667);
	}
	33.33333% {
		-moz-transform: scaleX(0.8);
	}
	41.66667% {
		-moz-transform: scaleX(0.73333);
	}
	50% {
		-moz-transform: scaleX(0.66667);
	}
	58.33333% {
		-moz-transform: scaleX(0.6);
	}
	66.66667% {
		-moz-transform: scaleX(0.53333);
	}
	75% {
		-moz-transform: scaleX(0.46667);
	}
	83.33333% {
		-moz-transform: scaleX(0.4);
	}
	91.66667% {
		-moz-transform: scaleX(0.33333);
	}
	100% {
		-moz-transform: scaleX(0.26667);
	}
}

@keyframes segment-opacity-12 {
	0% {
		opacity: 1;
	}
	8.33333% {
		opacity: 0.93333;
	}
	16.66667% {
		opacity: 0.86667;
	}
	25% {
		opacity: 0.8;
	}
	33.33333% {
		opacity: 0.73333;
	}
	41.66667% {
		opacity: 0.66667;
	}
	50% {
		opacity: 0.6;
	}
	58.33333% {
		opacity: 0.53333;
	}
	66.66667% {
		opacity: 0.46667;
	}
	75% {
		opacity: 0.4;
	}
	83.33333% {
		opacity: 0.33333;
	}
	91.66667% {
		opacity: 0.26667;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes segment-opacity-12 {
	0% {
		opacity: 1;
	}
	8.33333% {
		opacity: 0.93333;
	}
	16.66667% {
		opacity: 0.86667;
	}
	25% {
		opacity: 0.8;
	}
	33.33333% {
		opacity: 0.73333;
	}
	41.66667% {
		opacity: 0.66667;
	}
	50% {
		opacity: 0.6;
	}
	58.33333% {
		opacity: 0.53333;
	}
	66.66667% {
		opacity: 0.46667;
	}
	75% {
		opacity: 0.4;
	}
	83.33333% {
		opacity: 0.33333;
	}
	91.66667% {
		opacity: 0.26667;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes segment-opacity-12 {
	0% {
		opacity: 1;
	}
	8.33333% {
		opacity: 0.93333;
	}
	16.66667% {
		opacity: 0.86667;
	}
	25% {
		opacity: 0.8;
	}
	33.33333% {
		opacity: 0.73333;
	}
	41.66667% {
		opacity: 0.66667;
	}
	50% {
		opacity: 0.6;
	}
	58.33333% {
		opacity: 0.53333;
	}
	66.66667% {
		opacity: 0.46667;
	}
	75% {
		opacity: 0.4;
	}
	83.33333% {
		opacity: 0.33333;
	}
	91.66667% {
		opacity: 0.26667;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes segment-opacity-12 {
	0% {
		opacity: 1;
	}
	8.33333% {
		opacity: 0.93333;
	}
	16.66667% {
		opacity: 0.86667;
	}
	25% {
		opacity: 0.8;
	}
	33.33333% {
		opacity: 0.73333;
	}
	41.66667% {
		opacity: 0.66667;
	}
	50% {
		opacity: 0.6;
	}
	58.33333% {
		opacity: 0.53333;
	}
	66.66667% {
		opacity: 0.46667;
	}
	75% {
		opacity: 0.4;
	}
	83.33333% {
		opacity: 0.33333;
	}
	91.66667% {
		opacity: 0.26667;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes segment-opacity-12 {
	0% {
		opacity: 1;
	}
	8.33333% {
		opacity: 0.93333;
	}
	16.66667% {
		opacity: 0.86667;
	}
	25% {
		opacity: 0.8;
	}
	33.33333% {
		opacity: 0.73333;
	}
	41.66667% {
		opacity: 0.66667;
	}
	50% {
		opacity: 0.6;
	}
	58.33333% {
		opacity: 0.53333;
	}
	66.66667% {
		opacity: 0.46667;
	}
	75% {
		opacity: 0.4;
	}
	83.33333% {
		opacity: 0.33333;
	}
	91.66667% {
		opacity: 0.26667;
	}
	100% {
		opacity: 1;
	}
}

@keyframes segment-opacity-11 {
	0% {
		opacity: 0.93333;
	}
	8.33333% {
		opacity: 0.86667;
	}
	16.66667% {
		opacity: 0.8;
	}
	25% {
		opacity: 0.73333;
	}
	33.33333% {
		opacity: 0.66667;
	}
	41.66667% {
		opacity: 0.6;
	}
	50% {
		opacity: 0.53333;
	}
	58.33333% {
		opacity: 0.46667;
	}
	66.66667% {
		opacity: 0.4;
	}
	75% {
		opacity: 0.33333;
	}
	83.33333% {
		opacity: 0.26667;
	}
	91.66667% {
		opacity: 1;
	}
	100% {
		opacity: 0.93333;
	}
}

@-o-keyframes segment-opacity-11 {
	0% {
		opacity: 0.93333;
	}
	8.33333% {
		opacity: 0.86667;
	}
	16.66667% {
		opacity: 0.8;
	}
	25% {
		opacity: 0.73333;
	}
	33.33333% {
		opacity: 0.66667;
	}
	41.66667% {
		opacity: 0.6;
	}
	50% {
		opacity: 0.53333;
	}
	58.33333% {
		opacity: 0.46667;
	}
	66.66667% {
		opacity: 0.4;
	}
	75% {
		opacity: 0.33333;
	}
	83.33333% {
		opacity: 0.26667;
	}
	91.66667% {
		opacity: 1;
	}
	100% {
		opacity: 0.93333;
	}
}

@-ms-keyframes segment-opacity-11 {
	0% {
		opacity: 0.93333;
	}
	8.33333% {
		opacity: 0.86667;
	}
	16.66667% {
		opacity: 0.8;
	}
	25% {
		opacity: 0.73333;
	}
	33.33333% {
		opacity: 0.66667;
	}
	41.66667% {
		opacity: 0.6;
	}
	50% {
		opacity: 0.53333;
	}
	58.33333% {
		opacity: 0.46667;
	}
	66.66667% {
		opacity: 0.4;
	}
	75% {
		opacity: 0.33333;
	}
	83.33333% {
		opacity: 0.26667;
	}
	91.66667% {
		opacity: 1;
	}
	100% {
		opacity: 0.93333;
	}
}

@-webkit-keyframes segment-opacity-11 {
	0% {
		opacity: 0.93333;
	}
	8.33333% {
		opacity: 0.86667;
	}
	16.66667% {
		opacity: 0.8;
	}
	25% {
		opacity: 0.73333;
	}
	33.33333% {
		opacity: 0.66667;
	}
	41.66667% {
		opacity: 0.6;
	}
	50% {
		opacity: 0.53333;
	}
	58.33333% {
		opacity: 0.46667;
	}
	66.66667% {
		opacity: 0.4;
	}
	75% {
		opacity: 0.33333;
	}
	83.33333% {
		opacity: 0.26667;
	}
	91.66667% {
		opacity: 1;
	}
	100% {
		opacity: 0.93333;
	}
}

@-moz-keyframes segment-opacity-11 {
	0% {
		opacity: 0.93333;
	}
	8.33333% {
		opacity: 0.86667;
	}
	16.66667% {
		opacity: 0.8;
	}
	25% {
		opacity: 0.73333;
	}
	33.33333% {
		opacity: 0.66667;
	}
	41.66667% {
		opacity: 0.6;
	}
	50% {
		opacity: 0.53333;
	}
	58.33333% {
		opacity: 0.46667;
	}
	66.66667% {
		opacity: 0.4;
	}
	75% {
		opacity: 0.33333;
	}
	83.33333% {
		opacity: 0.26667;
	}
	91.66667% {
		opacity: 1;
	}
	100% {
		opacity: 0.93333;
	}
}

@keyframes segment-opacity-10 {
	0% {
		opacity: 0.86667;
	}
	8.33333% {
		opacity: 0.8;
	}
	16.66667% {
		opacity: 0.73333;
	}
	25% {
		opacity: 0.66667;
	}
	33.33333% {
		opacity: 0.6;
	}
	41.66667% {
		opacity: 0.53333;
	}
	50% {
		opacity: 0.46667;
	}
	58.33333% {
		opacity: 0.4;
	}
	66.66667% {
		opacity: 0.33333;
	}
	75% {
		opacity: 0.26667;
	}
	83.33333% {
		opacity: 1;
	}
	91.66667% {
		opacity: 0.93333;
	}
	100% {
		opacity: 0.86667;
	}
}

@-o-keyframes segment-opacity-10 {
	0% {
		opacity: 0.86667;
	}
	8.33333% {
		opacity: 0.8;
	}
	16.66667% {
		opacity: 0.73333;
	}
	25% {
		opacity: 0.66667;
	}
	33.33333% {
		opacity: 0.6;
	}
	41.66667% {
		opacity: 0.53333;
	}
	50% {
		opacity: 0.46667;
	}
	58.33333% {
		opacity: 0.4;
	}
	66.66667% {
		opacity: 0.33333;
	}
	75% {
		opacity: 0.26667;
	}
	83.33333% {
		opacity: 1;
	}
	91.66667% {
		opacity: 0.93333;
	}
	100% {
		opacity: 0.86667;
	}
}

@-ms-keyframes segment-opacity-10 {
	0% {
		opacity: 0.86667;
	}
	8.33333% {
		opacity: 0.8;
	}
	16.66667% {
		opacity: 0.73333;
	}
	25% {
		opacity: 0.66667;
	}
	33.33333% {
		opacity: 0.6;
	}
	41.66667% {
		opacity: 0.53333;
	}
	50% {
		opacity: 0.46667;
	}
	58.33333% {
		opacity: 0.4;
	}
	66.66667% {
		opacity: 0.33333;
	}
	75% {
		opacity: 0.26667;
	}
	83.33333% {
		opacity: 1;
	}
	91.66667% {
		opacity: 0.93333;
	}
	100% {
		opacity: 0.86667;
	}
}

@-webkit-keyframes segment-opacity-10 {
	0% {
		opacity: 0.86667;
	}
	8.33333% {
		opacity: 0.8;
	}
	16.66667% {
		opacity: 0.73333;
	}
	25% {
		opacity: 0.66667;
	}
	33.33333% {
		opacity: 0.6;
	}
	41.66667% {
		opacity: 0.53333;
	}
	50% {
		opacity: 0.46667;
	}
	58.33333% {
		opacity: 0.4;
	}
	66.66667% {
		opacity: 0.33333;
	}
	75% {
		opacity: 0.26667;
	}
	83.33333% {
		opacity: 1;
	}
	91.66667% {
		opacity: 0.93333;
	}
	100% {
		opacity: 0.86667;
	}
}

@-moz-keyframes segment-opacity-10 {
	0% {
		opacity: 0.86667;
	}
	8.33333% {
		opacity: 0.8;
	}
	16.66667% {
		opacity: 0.73333;
	}
	25% {
		opacity: 0.66667;
	}
	33.33333% {
		opacity: 0.6;
	}
	41.66667% {
		opacity: 0.53333;
	}
	50% {
		opacity: 0.46667;
	}
	58.33333% {
		opacity: 0.4;
	}
	66.66667% {
		opacity: 0.33333;
	}
	75% {
		opacity: 0.26667;
	}
	83.33333% {
		opacity: 1;
	}
	91.66667% {
		opacity: 0.93333;
	}
	100% {
		opacity: 0.86667;
	}
}

@keyframes segment-opacity-9 {
	0% {
		opacity: 0.8;
	}
	8.33333% {
		opacity: 0.73333;
	}
	16.66667% {
		opacity: 0.66667;
	}
	25% {
		opacity: 0.6;
	}
	33.33333% {
		opacity: 0.53333;
	}
	41.66667% {
		opacity: 0.46667;
	}
	50% {
		opacity: 0.4;
	}
	58.33333% {
		opacity: 0.33333;
	}
	66.66667% {
		opacity: 0.26667;
	}
	75% {
		opacity: 1;
	}
	83.33333% {
		opacity: 0.933333;
	}
	91.66667% {
		opacity: 0.86667;
	}
	100% {
		opacity: 0.8;
	}
}

@-o-keyframes segment-opacity-9 {
	0% {
		opacity: 0.8;
	}
	8.33333% {
		opacity: 0.73333;
	}
	16.66667% {
		opacity: 0.66667;
	}
	25% {
		opacity: 0.6;
	}
	33.33333% {
		opacity: 0.53333;
	}
	41.66667% {
		opacity: 0.46667;
	}
	50% {
		opacity: 0.4;
	}
	58.33333% {
		opacity: 0.33333;
	}
	66.66667% {
		opacity: 0.26667;
	}
	75% {
		opacity: 1;
	}
	83.33333% {
		opacity: 0.933333;
	}
	91.66667% {
		opacity: 0.86667;
	}
	100% {
		opacity: 0.8;
	}
}

@-ms-keyframes segment-opacity-9 {
	0% {
		opacity: 0.8;
	}
	8.33333% {
		opacity: 0.73333;
	}
	16.66667% {
		opacity: 0.66667;
	}
	25% {
		opacity: 0.6;
	}
	33.33333% {
		opacity: 0.53333;
	}
	41.66667% {
		opacity: 0.46667;
	}
	50% {
		opacity: 0.4;
	}
	58.33333% {
		opacity: 0.33333;
	}
	66.66667% {
		opacity: 0.26667;
	}
	75% {
		opacity: 1;
	}
	83.33333% {
		opacity: 0.933333;
	}
	91.66667% {
		opacity: 0.86667;
	}
	100% {
		opacity: 0.8;
	}
}

@-webkit-keyframes segment-opacity-9 {
	0% {
		opacity: 0.8;
	}
	8.33333% {
		opacity: 0.73333;
	}
	16.66667% {
		opacity: 0.66667;
	}
	25% {
		opacity: 0.6;
	}
	33.33333% {
		opacity: 0.53333;
	}
	41.66667% {
		opacity: 0.46667;
	}
	50% {
		opacity: 0.4;
	}
	58.33333% {
		opacity: 0.33333;
	}
	66.66667% {
		opacity: 0.26667;
	}
	75% {
		opacity: 1;
	}
	83.33333% {
		opacity: 0.933333;
	}
	91.66667% {
		opacity: 0.86667;
	}
	100% {
		opacity: 0.8;
	}
}

@-moz-keyframes segment-opacity-9 {
	0% {
		opacity: 0.8;
	}
	8.33333% {
		opacity: 0.73333;
	}
	16.66667% {
		opacity: 0.66667;
	}
	25% {
		opacity: 0.6;
	}
	33.33333% {
		opacity: 0.53333;
	}
	41.66667% {
		opacity: 0.46667;
	}
	50% {
		opacity: 0.4;
	}
	58.33333% {
		opacity: 0.33333;
	}
	66.66667% {
		opacity: 0.26667;
	}
	75% {
		opacity: 1;
	}
	83.33333% {
		opacity: 0.933333;
	}
	91.66667% {
		opacity: 0.86667;
	}
	100% {
		opacity: 0.8;
	}
}

@keyframes segment-opacity-8 {
	0% {
		opacity: 0.73333;
	}
	8.33333% {
		opacity: 0.66667;
	}
	16.66667% {
		opacity: 0.6;
	}
	25% {
		opacity: 0.53333;
	}
	33.33333% {
		opacity: 0.46667;
	}
	41.66667% {
		opacity: 0.4;
	}
	50% {
		opacity: 0.33333;
	}
	58.33333% {
		opacity: 0.26667;
	}
	66.66667% {
		opacity: 1;
	}
	75% {
		opacity: 0.93333;
	}
	83.33333% {
		opacity: 0.86667;
	}
	91.66667% {
		opacity: 0.8;
	}
	100% {
		opacity: 0.73333;
	}
}

@-o-keyframes segment-opacity-8 {
	0% {
		opacity: 0.73333;
	}
	8.33333% {
		opacity: 0.66667;
	}
	16.66667% {
		opacity: 0.6;
	}
	25% {
		opacity: 0.53333;
	}
	33.33333% {
		opacity: 0.46667;
	}
	41.66667% {
		opacity: 0.4;
	}
	50% {
		opacity: 0.33333;
	}
	58.33333% {
		opacity: 0.26667;
	}
	66.66667% {
		opacity: 1;
	}
	75% {
		opacity: 0.93333;
	}
	83.33333% {
		opacity: 0.86667;
	}
	91.66667% {
		opacity: 0.8;
	}
	100% {
		opacity: 0.73333;
	}
}

@-ms-keyframes segment-opacity-8 {
	0% {
		opacity: 0.73333;
	}
	8.33333% {
		opacity: 0.66667;
	}
	16.66667% {
		opacity: 0.6;
	}
	25% {
		opacity: 0.53333;
	}
	33.33333% {
		opacity: 0.46667;
	}
	41.66667% {
		opacity: 0.4;
	}
	50% {
		opacity: 0.33333;
	}
	58.33333% {
		opacity: 0.26667;
	}
	66.66667% {
		opacity: 1;
	}
	75% {
		opacity: 0.93333;
	}
	83.33333% {
		opacity: 0.86667;
	}
	91.66667% {
		opacity: 0.8;
	}
	100% {
		opacity: 0.73333;
	}
}

@-webkit-keyframes segment-opacity-8 {
	0% {
		opacity: 0.73333;
	}
	8.33333% {
		opacity: 0.66667;
	}
	16.66667% {
		opacity: 0.6;
	}
	25% {
		opacity: 0.53333;
	}
	33.33333% {
		opacity: 0.46667;
	}
	41.66667% {
		opacity: 0.4;
	}
	50% {
		opacity: 0.33333;
	}
	58.33333% {
		opacity: 0.26667;
	}
	66.66667% {
		opacity: 1;
	}
	75% {
		opacity: 0.93333;
	}
	83.33333% {
		opacity: 0.86667;
	}
	91.66667% {
		opacity: 0.8;
	}
	100% {
		opacity: 0.73333;
	}
}

@-moz-keyframes segment-opacity-8 {
	0% {
		opacity: 0.73333;
	}
	8.33333% {
		opacity: 0.66667;
	}
	16.66667% {
		opacity: 0.6;
	}
	25% {
		opacity: 0.53333;
	}
	33.33333% {
		opacity: 0.46667;
	}
	41.66667% {
		opacity: 0.4;
	}
	50% {
		opacity: 0.33333;
	}
	58.33333% {
		opacity: 0.26667;
	}
	66.66667% {
		opacity: 1;
	}
	75% {
		opacity: 0.93333;
	}
	83.33333% {
		opacity: 0.86667;
	}
	91.66667% {
		opacity: 0.8;
	}
	100% {
		opacity: 0.73333;
	}
}

@keyframes segment-opacity-7 {
	0% {
		opacity: 0.66667;
	}
	8.33333% {
		opacity: 0.6;
	}
	16.66667% {
		opacity: 0.53333;
	}
	25% {
		opacity: 0.46667;
	}
	33.33333% {
		opacity: 0.4;
	}
	41.66667% {
		opacity: 0.33333;
	}
	50% {
		opacity: 0.26667;
	}
	58.33333% {
		opacity: 1;
	}
	66.66667% {
		opacity: 0.93333;
	}
	75% {
		opacity: 0.86667;
	}
	83.33333% {
		opacity: 0.8;
	}
	91.66667% {
		opacity: 0.73333;
	}
	100% {
		opacity: 0.66667;
	}
}

@-o-keyframes segment-opacity-7 {
	0% {
		opacity: 0.66667;
	}
	8.33333% {
		opacity: 0.6;
	}
	16.66667% {
		opacity: 0.53333;
	}
	25% {
		opacity: 0.46667;
	}
	33.33333% {
		opacity: 0.4;
	}
	41.66667% {
		opacity: 0.33333;
	}
	50% {
		opacity: 0.26667;
	}
	58.33333% {
		opacity: 1;
	}
	66.66667% {
		opacity: 0.93333;
	}
	75% {
		opacity: 0.86667;
	}
	83.33333% {
		opacity: 0.8;
	}
	91.66667% {
		opacity: 0.73333;
	}
	100% {
		opacity: 0.66667;
	}
}

@-ms-keyframes segment-opacity-7 {
	0% {
		opacity: 0.66667;
	}
	8.33333% {
		opacity: 0.6;
	}
	16.66667% {
		opacity: 0.53333;
	}
	25% {
		opacity: 0.46667;
	}
	33.33333% {
		opacity: 0.4;
	}
	41.66667% {
		opacity: 0.33333;
	}
	50% {
		opacity: 0.26667;
	}
	58.33333% {
		opacity: 1;
	}
	66.66667% {
		opacity: 0.93333;
	}
	75% {
		opacity: 0.86667;
	}
	83.33333% {
		opacity: 0.8;
	}
	91.66667% {
		opacity: 0.73333;
	}
	100% {
		opacity: 0.66667;
	}
}

@-webkit-keyframes segment-opacity-7 {
	0% {
		opacity: 0.66667;
	}
	8.33333% {
		opacity: 0.6;
	}
	16.66667% {
		opacity: 0.53333;
	}
	25% {
		opacity: 0.46667;
	}
	33.33333% {
		opacity: 0.4;
	}
	41.66667% {
		opacity: 0.33333;
	}
	50% {
		opacity: 0.26667;
	}
	58.33333% {
		opacity: 1;
	}
	66.66667% {
		opacity: 0.93333;
	}
	75% {
		opacity: 0.86667;
	}
	83.33333% {
		opacity: 0.8;
	}
	91.66667% {
		opacity: 0.73333;
	}
	100% {
		opacity: 0.66667;
	}
}

@-moz-keyframes segment-opacity-7 {
	0% {
		opacity: 0.66667;
	}
	8.33333% {
		opacity: 0.6;
	}
	16.66667% {
		opacity: 0.53333;
	}
	25% {
		opacity: 0.46667;
	}
	33.33333% {
		opacity: 0.4;
	}
	41.66667% {
		opacity: 0.33333;
	}
	50% {
		opacity: 0.26667;
	}
	58.33333% {
		opacity: 1;
	}
	66.66667% {
		opacity: 0.93333;
	}
	75% {
		opacity: 0.86667;
	}
	83.33333% {
		opacity: 0.8;
	}
	91.66667% {
		opacity: 0.73333;
	}
	100% {
		opacity: 0.66667;
	}
}

@keyframes segment-opacity-6 {
	0% {
		opacity: 0.6;
	}
	8.33333% {
		opacity: 0.53333;
	}
	16.66667% {
		opacity: 0.46667;
	}
	25% {
		opacity: 0.4;
	}
	33.33333% {
		opacity: 0.33333;
	}
	41.66667% {
		opacity: 0.26667;
	}
	50% {
		opacity: 1;
	}
	58.33333% {
		opacity: 0.93333;
	}
	66.66667% {
		opacity: 0.86667;
	}
	75% {
		opacity: 0.8;
	}
	83.33333% {
		opacity: 0.73333;
	}
	91.66667% {
		acity: 0.66667;
	}
	100% {
		opacity: 0.6;
	}
}

@-o-keyframes segment-opacity-6 {
	0% {
		opacity: 0.6;
	}
	8.33333% {
		opacity: 0.53333;
	}
	16.66667% {
		opacity: 0.46667;
	}
	25% {
		opacity: 0.4;
	}
	33.33333% {
		opacity: 0.33333;
	}
	41.66667% {
		opacity: 0.26667;
	}
	50% {
		opacity: 1;
	}
	58.33333% {
		opacity: 0.93333;
	}
	66.66667% {
		opacity: 0.86667;
	}
	75% {
		opacity: 0.8;
	}
	83.33333% {
		opacity: 0.73333;
	}
	91.66667% {
		acity: 0.66667;
	}
	100% {
		opacity: 0.6;
	}
}

@-ms-keyframes segment-opacity-6 {
	0% {
		opacity: 0.6;
	}
	8.33333% {
		opacity: 0.53333;
	}
	16.66667% {
		opacity: 0.46667;
	}
	25% {
		opacity: 0.4;
	}
	33.33333% {
		opacity: 0.33333;
	}
	41.66667% {
		opacity: 0.26667;
	}
	50% {
		opacity: 1;
	}
	58.33333% {
		opacity: 0.93333;
	}
	66.66667% {
		opacity: 0.86667;
	}
	75% {
		opacity: 0.8;
	}
	83.33333% {
		opacity: 0.73333;
	}
	91.66667% {
		acity: 0.66667;
	}
	100% {
		opacity: 0.6;
	}
}

@-webkit-keyframes segment-opacity-6 {
	0% {
		opacity: 0.6;
	}
	8.33333% {
		opacity: 0.53333;
	}
	16.66667% {
		opacity: 0.46667;
	}
	25% {
		opacity: 0.4;
	}
	33.33333% {
		opacity: 0.33333;
	}
	41.66667% {
		opacity: 0.26667;
	}
	50% {
		opacity: 1;
	}
	58.33333% {
		opacity: 0.93333;
	}
	66.66667% {
		opacity: 0.86667;
	}
	75% {
		opacity: 0.8;
	}
	83.33333% {
		opacity: 0.73333;
	}
	91.66667% {
		acity: 0.66667;
	}
	100% {
		opacity: 0.6;
	}
}

@-moz-keyframes segment-opacity-6 {
	0% {
		opacity: 0.6;
	}
	8.33333% {
		opacity: 0.53333;
	}
	16.66667% {
		opacity: 0.46667;
	}
	25% {
		opacity: 0.4;
	}
	33.33333% {
		opacity: 0.33333;
	}
	41.66667% {
		opacity: 0.26667;
	}
	50% {
		opacity: 1;
	}
	58.33333% {
		opacity: 0.93333;
	}
	66.66667% {
		opacity: 0.86667;
	}
	75% {
		opacity: 0.8;
	}
	83.33333% {
		opacity: 0.73333;
	}
	91.66667% {
		acity: 0.66667;
	}
	100% {
		opacity: 0.6;
	}
}

@keyframes segment-opacity-5 {
	0% {
		opacity: 0.53333;
	}
	8.33333% {
		opacity: 0.46667;
	}
	16.66667% {
		opacity: 0.4;
	}
	25% {
		opacity: 0.33333;
	}
	33.33333% {
		opacity: 0.26667;
	}
	41.66667% {
		opacity: 1;
	}
	50% {
		opacity: 0.93333;
	}
	58.33333% {
		opacity: 0.86667;
	}
	66.66667% {
		opacity: 0.8;
	}
	75% {
		opacity: 0.73333;
	}
	83.33333% {
		opacity: 0.66667;
	}
	91.66667% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.53333;
	}
}

@-o-keyframes segment-opacity-5 {
	0% {
		opacity: 0.53333;
	}
	8.33333% {
		opacity: 0.46667;
	}
	16.66667% {
		opacity: 0.4;
	}
	25% {
		opacity: 0.33333;
	}
	33.33333% {
		opacity: 0.26667;
	}
	41.66667% {
		opacity: 1;
	}
	50% {
		opacity: 0.93333;
	}
	58.33333% {
		opacity: 0.86667;
	}
	66.66667% {
		opacity: 0.8;
	}
	75% {
		opacity: 0.73333;
	}
	83.33333% {
		opacity: 0.66667;
	}
	91.66667% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.53333;
	}
}

@-ms-keyframes segment-opacity-5 {
	0% {
		opacity: 0.53333;
	}
	8.33333% {
		opacity: 0.46667;
	}
	16.66667% {
		opacity: 0.4;
	}
	25% {
		opacity: 0.33333;
	}
	33.33333% {
		opacity: 0.26667;
	}
	41.66667% {
		opacity: 1;
	}
	50% {
		opacity: 0.93333;
	}
	58.33333% {
		opacity: 0.86667;
	}
	66.66667% {
		opacity: 0.8;
	}
	75% {
		opacity: 0.73333;
	}
	83.33333% {
		opacity: 0.66667;
	}
	91.66667% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.53333;
	}
}

@-webkit-keyframes segment-opacity-5 {
	0% {
		opacity: 0.53333;
	}
	8.33333% {
		opacity: 0.46667;
	}
	16.66667% {
		opacity: 0.4;
	}
	25% {
		opacity: 0.33333;
	}
	33.33333% {
		opacity: 0.26667;
	}
	41.66667% {
		opacity: 1;
	}
	50% {
		opacity: 0.93333;
	}
	58.33333% {
		opacity: 0.86667;
	}
	66.66667% {
		opacity: 0.8;
	}
	75% {
		opacity: 0.73333;
	}
	83.33333% {
		opacity: 0.66667;
	}
	91.66667% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.53333;
	}
}

@-moz-keyframes segment-opacity-5 {
	0% {
		opacity: 0.53333;
	}
	8.33333% {
		opacity: 0.46667;
	}
	16.66667% {
		opacity: 0.4;
	}
	25% {
		opacity: 0.33333;
	}
	33.33333% {
		opacity: 0.26667;
	}
	41.66667% {
		opacity: 1;
	}
	50% {
		opacity: 0.93333;
	}
	58.33333% {
		opacity: 0.86667;
	}
	66.66667% {
		opacity: 0.8;
	}
	75% {
		opacity: 0.73333;
	}
	83.33333% {
		opacity: 0.66667;
	}
	91.66667% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.53333;
	}
}

@keyframes segment-opacity-4 {
	0% {
		opacity: 0.46667;
	}
	8.33333% {
		opacity: 0.4;
	}
	16.66667% {
		opacity: 0.33333;
	}
	25% {
		opacity: 0.26667;
	}
	33.33333% {
		opacity: 1;
	}
	41.66667% {
		opacity: 0.93333;
	}
	50% {
		opacity: 0.86667;
	}
	58.33333% {
		opacity: 0.8;
	}
	66.66667% {
		opacity: 0.73333;
	}
	75% {
		opacity: 0.66667;
	}
	83.33333% {
		opacity: 0.6;
	}
	91.66667% {
		opacity: 0.53333;
	}
	100% {
		opacity: 0.46667;
	}
}

@-o-keyframes segment-opacity-4 {
	0% {
		opacity: 0.46667;
	}
	8.33333% {
		opacity: 0.4;
	}
	16.66667% {
		opacity: 0.33333;
	}
	25% {
		opacity: 0.26667;
	}
	33.33333% {
		opacity: 1;
	}
	41.66667% {
		opacity: 0.93333;
	}
	50% {
		opacity: 0.86667;
	}
	58.33333% {
		opacity: 0.8;
	}
	66.66667% {
		opacity: 0.73333;
	}
	75% {
		opacity: 0.66667;
	}
	83.33333% {
		opacity: 0.6;
	}
	91.66667% {
		opacity: 0.53333;
	}
	100% {
		opacity: 0.46667;
	}
}

@-ms-keyframes segment-opacity-4 {
	0% {
		opacity: 0.46667;
	}
	8.33333% {
		opacity: 0.4;
	}
	16.66667% {
		opacity: 0.33333;
	}
	25% {
		opacity: 0.26667;
	}
	33.33333% {
		opacity: 1;
	}
	41.66667% {
		opacity: 0.93333;
	}
	50% {
		opacity: 0.86667;
	}
	58.33333% {
		opacity: 0.8;
	}
	66.66667% {
		opacity: 0.73333;
	}
	75% {
		opacity: 0.66667;
	}
	83.33333% {
		opacity: 0.6;
	}
	91.66667% {
		opacity: 0.53333;
	}
	100% {
		opacity: 0.46667;
	}
}

@-webkit-keyframes segment-opacity-4 {
	0% {
		opacity: 0.46667;
	}
	8.33333% {
		opacity: 0.4;
	}
	16.66667% {
		opacity: 0.33333;
	}
	25% {
		opacity: 0.26667;
	}
	33.33333% {
		opacity: 1;
	}
	41.66667% {
		opacity: 0.93333;
	}
	50% {
		opacity: 0.86667;
	}
	58.33333% {
		opacity: 0.8;
	}
	66.66667% {
		opacity: 0.73333;
	}
	75% {
		opacity: 0.66667;
	}
	83.33333% {
		opacity: 0.6;
	}
	91.66667% {
		opacity: 0.53333;
	}
	100% {
		opacity: 0.46667;
	}
}

@-moz-keyframes segment-opacity-4 {
	0% {
		opacity: 0.46667;
	}
	8.33333% {
		opacity: 0.4;
	}
	16.66667% {
		opacity: 0.33333;
	}
	25% {
		opacity: 0.26667;
	}
	33.33333% {
		opacity: 1;
	}
	41.66667% {
		opacity: 0.93333;
	}
	50% {
		opacity: 0.86667;
	}
	58.33333% {
		opacity: 0.8;
	}
	66.66667% {
		opacity: 0.73333;
	}
	75% {
		opacity: 0.66667;
	}
	83.33333% {
		opacity: 0.6;
	}
	91.66667% {
		opacity: 0.53333;
	}
	100% {
		opacity: 0.46667;
	}
}

@keyframes segment-opacity-3 {
	0% {
		opacity: 0.4;
	}
	8.33333% {
		opacity: 0.33333;
	}
	16.66667% {
		opacity: 0.26667;
	}
	25% {
		opacity: 1;
	}
	33.33333% {
		opacity: 0.93333;
	}
	41.66667% {
		opacity: 0.86667;
	}
	50% {
		opacity: 0.8;
	}
	58.33333% {
		opacity: 0.73333;
	}
	66.66667% {
		opacity: 0.66667;
	}
	75% {
		opacity: 0.6;
	}
	83.33333% {
		opacity: 0.53333;
	}
	91.66667% {
		opacity: 0.46667;
	}
	100% {
		opacity: 0.4;
	}
}

@-o-keyframes segment-opacity-3 {
	0% {
		opacity: 0.4;
	}
	8.33333% {
		opacity: 0.33333;
	}
	16.66667% {
		opacity: 0.26667;
	}
	25% {
		opacity: 1;
	}
	33.33333% {
		opacity: 0.93333;
	}
	41.66667% {
		opacity: 0.86667;
	}
	50% {
		opacity: 0.8;
	}
	58.33333% {
		opacity: 0.73333;
	}
	66.66667% {
		opacity: 0.66667;
	}
	75% {
		opacity: 0.6;
	}
	83.33333% {
		opacity: 0.53333;
	}
	91.66667% {
		opacity: 0.46667;
	}
	100% {
		opacity: 0.4;
	}
}

@-ms-keyframes segment-opacity-3 {
	0% {
		opacity: 0.4;
	}
	8.33333% {
		opacity: 0.33333;
	}
	16.66667% {
		opacity: 0.26667;
	}
	25% {
		opacity: 1;
	}
	33.33333% {
		opacity: 0.93333;
	}
	41.66667% {
		opacity: 0.86667;
	}
	50% {
		opacity: 0.8;
	}
	58.33333% {
		opacity: 0.73333;
	}
	66.66667% {
		opacity: 0.66667;
	}
	75% {
		opacity: 0.6;
	}
	83.33333% {
		opacity: 0.53333;
	}
	91.66667% {
		opacity: 0.46667;
	}
	100% {
		opacity: 0.4;
	}
}

@-webkit-keyframes segment-opacity-3 {
	0% {
		opacity: 0.4;
	}
	8.33333% {
		opacity: 0.33333;
	}
	16.66667% {
		opacity: 0.26667;
	}
	25% {
		opacity: 1;
	}
	33.33333% {
		opacity: 0.93333;
	}
	41.66667% {
		opacity: 0.86667;
	}
	50% {
		opacity: 0.8;
	}
	58.33333% {
		opacity: 0.73333;
	}
	66.66667% {
		opacity: 0.66667;
	}
	75% {
		opacity: 0.6;
	}
	83.33333% {
		opacity: 0.53333;
	}
	91.66667% {
		opacity: 0.46667;
	}
	100% {
		opacity: 0.4;
	}
}

@-moz-keyframes segment-opacity-3 {
	0% {
		opacity: 0.4;
	}
	8.33333% {
		opacity: 0.33333;
	}
	16.66667% {
		opacity: 0.26667;
	}
	25% {
		opacity: 1;
	}
	33.33333% {
		opacity: 0.93333;
	}
	41.66667% {
		opacity: 0.86667;
	}
	50% {
		opacity: 0.8;
	}
	58.33333% {
		opacity: 0.73333;
	}
	66.66667% {
		opacity: 0.66667;
	}
	75% {
		opacity: 0.6;
	}
	83.33333% {
		opacity: 0.53333;
	}
	91.66667% {
		opacity: 0.46667;
	}
	100% {
		opacity: 0.4;
	}
}

@keyframes segment-opacity-2 {
	0% {
		opacity: 0.33333;
	}
	8.33333% {
		opacity: 0.26667;
	}
	16.66667% {
		opacity: 1;
	}
	25% {
		opacity: 0.93333;
	}
	33.33333% {
		opacity: 0.86667;
	}
	41.66667% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.73333;
	}
	58.33333% {
		opacity: 0.66667;
	}
	66.66667% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.53333;
	}
	83.33333% {
		opacity: 0.46667;
	}
	91.66667% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.33333;
	}
}

@-o-keyframes segment-opacity-2 {
	0% {
		opacity: 0.33333;
	}
	8.33333% {
		opacity: 0.26667;
	}
	16.66667% {
		opacity: 1;
	}
	25% {
		opacity: 0.93333;
	}
	33.33333% {
		opacity: 0.86667;
	}
	41.66667% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.73333;
	}
	58.33333% {
		opacity: 0.66667;
	}
	66.66667% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.53333;
	}
	83.33333% {
		opacity: 0.46667;
	}
	91.66667% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.33333;
	}
}

@-ms-keyframes segment-opacity-2 {
	0% {
		opacity: 0.33333;
	}
	8.33333% {
		opacity: 0.26667;
	}
	16.66667% {
		opacity: 1;
	}
	25% {
		opacity: 0.93333;
	}
	33.33333% {
		opacity: 0.86667;
	}
	41.66667% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.73333;
	}
	58.33333% {
		opacity: 0.66667;
	}
	66.66667% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.53333;
	}
	83.33333% {
		opacity: 0.46667;
	}
	91.66667% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.33333;
	}
}

@-webkit-keyframes segment-opacity-2 {
	0% {
		opacity: 0.33333;
	}
	8.33333% {
		opacity: 0.26667;
	}
	16.66667% {
		opacity: 1;
	}
	25% {
		opacity: 0.93333;
	}
	33.33333% {
		opacity: 0.86667;
	}
	41.66667% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.73333;
	}
	58.33333% {
		opacity: 0.66667;
	}
	66.66667% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.53333;
	}
	83.33333% {
		opacity: 0.46667;
	}
	91.66667% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.33333;
	}
}

@-moz-keyframes segment-opacity-2 {
	0% {
		opacity: 0.33333;
	}
	8.33333% {
		opacity: 0.26667;
	}
	16.66667% {
		opacity: 1;
	}
	25% {
		opacity: 0.93333;
	}
	33.33333% {
		opacity: 0.86667;
	}
	41.66667% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.73333;
	}
	58.33333% {
		opacity: 0.66667;
	}
	66.66667% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.53333;
	}
	83.33333% {
		opacity: 0.46667;
	}
	91.66667% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.33333;
	}
}

@keyframes segment-opacity-1 {
	0% {
		opacity: 0.26667;
	}
	8.33333% {
		opacity: 1;
	}
	16.66667% {
		opacity: 0.93333;
	}
	25% {
		opacity: 0.86667;
	}
	33.33333% {
		opacity: 0.8;
	}
	41.66667% {
		opacity: 0.73333;
	}
	50% {
		opacity: 0.66667;
	}
	58.33333% {
		opacity: 0.6;
	}
	66.66667% {
		opacity: 0.53333;
	}
	75% {
		opacity: 0.46667;
	}
	83.33333% {
		opacity: 0.4;
	}
	91.66667% {
		opacity: 0.33333;
	}
	100% {
		opacity: 0.26667;
	}
}

@-o-keyframes segment-opacity-1 {
	0% {
		opacity: 0.26667;
	}
	8.33333% {
		opacity: 1;
	}
	16.66667% {
		opacity: 0.93333;
	}
	25% {
		opacity: 0.86667;
	}
	33.33333% {
		opacity: 0.8;
	}
	41.66667% {
		opacity: 0.73333;
	}
	50% {
		opacity: 0.66667;
	}
	58.33333% {
		opacity: 0.6;
	}
	66.66667% {
		opacity: 0.53333;
	}
	75% {
		opacity: 0.46667;
	}
	83.33333% {
		opacity: 0.4;
	}
	91.66667% {
		opacity: 0.33333;
	}
	100% {
		opacity: 0.26667;
	}
}

@-ms-keyframes segment-opacity-1 {
	0% {
		opacity: 0.26667;
	}
	8.33333% {
		opacity: 1;
	}
	16.66667% {
		opacity: 0.93333;
	}
	25% {
		opacity: 0.86667;
	}
	33.33333% {
		opacity: 0.8;
	}
	41.66667% {
		opacity: 0.73333;
	}
	50% {
		opacity: 0.66667;
	}
	58.33333% {
		opacity: 0.6;
	}
	66.66667% {
		opacity: 0.53333;
	}
	75% {
		opacity: 0.46667;
	}
	83.33333% {
		opacity: 0.4;
	}
	91.66667% {
		opacity: 0.33333;
	}
	100% {
		opacity: 0.26667;
	}
}

@-webkit-keyframes segment-opacity-1 {
	0% {
		opacity: 0.26667;
	}
	8.33333% {
		opacity: 1;
	}
	16.66667% {
		opacity: 0.93333;
	}
	25% {
		opacity: 0.86667;
	}
	33.33333% {
		opacity: 0.8;
	}
	41.66667% {
		opacity: 0.73333;
	}
	50% {
		opacity: 0.66667;
	}
	58.33333% {
		opacity: 0.6;
	}
	66.66667% {
		opacity: 0.53333;
	}
	75% {
		opacity: 0.46667;
	}
	83.33333% {
		opacity: 0.4;
	}
	91.66667% {
		opacity: 0.33333;
	}
	100% {
		opacity: 0.26667;
	}
}

@-moz-keyframes segment-opacity-1 {
	0% {
		opacity: 0.26667;
	}
	8.33333% {
		opacity: 1;
	}
	16.66667% {
		opacity: 0.93333;
	}
	25% {
		opacity: 0.86667;
	}
	33.33333% {
		opacity: 0.8;
	}
	41.66667% {
		opacity: 0.73333;
	}
	50% {
		opacity: 0.66667;
	}
	58.33333% {
		opacity: 0.6;
	}
	66.66667% {
		opacity: 0.53333;
	}
	75% {
		opacity: 0.46667;
	}
	83.33333% {
		opacity: 0.4;
	}
	91.66667% {
		opacity: 0.33333;
	}
	100% {
		opacity: 0.26667;
	}
}